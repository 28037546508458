interface BrokerPoints {
    distribution: {
        base: number,
        socials: number,
        holdings: number,
        alphaClub: number,
        children: number
    },
    total: number
}
interface HistoricalScorePostInfo {
    boardPostId: string,
    score: number,
    creationTimestamp: number,
}
interface HistoricalScoreData {
    lowest: HistoricalScorePostInfo,
    highest: HistoricalScorePostInfo,
    all: HistoricalScorePostInfo[],
    averages: {
        lifetime: number,
        previousYear: number,
        previousQuarter: number,
        previousMonth: number,
        previousWeek: number,
    },
    mostRecent: HistoricalScorePostInfo,
}
interface TokenTypeUserAlphaInfo {
    numPosts: { total: number, analyzed: number },
    // numComments: number,
    createdPosts: { boardPostId: string, creationTimestamp: number }[],
    ratings: HistoricalScoreData | null,
    pnl: HistoricalScoreData | null,
    interactions: { mostUpvotes: HistoricalScorePostInfo, mostDownvotes: HistoricalScorePostInfo } | null,
    savedPostsIds: string[],
}
interface EliteClubInfo {
    firstPaymentDate: string,
    lastPaymentDate: string,
    membershipExpiryDate: string,
    membershipExpiryTimestamp: string,
    isEliteCreator: boolean,
}
export interface UserAccount {
    userAuth: {
        twitter: { name: string | null, following: boolean, id: string | null };
        telegram: { name: string | null, id: string | null, mergedAccountId?: string };
        discord: { name: string | null, id: string | null, mergedAccountId?: string };
        wallet: string | null;
    };
    userAlphaInfo: {
        crypto: TokenTypeUserAlphaInfo,
        nft: TokenTypeUserAlphaInfo,
        eliteClubInfo: EliteClubInfo | null,
    },
    userBrokerInfo: {
        parentBrokerId: string | null,
        invitedBrokersIds: string[],
        generation: number;
        points: {
            lifetime: BrokerPoints,
            monthly: BrokerPoints,
        },
        rank: number | null,
        isBroker: boolean,
    },
    userInfo: { username: string | null, pfpUrl: string | null },
    userTimestamps: { accountCreationTimestamp: number, joinedBrokerageTimestamp: number | null },
    userId: string,
    isMember: boolean,
}


export interface BoardPostInfo {
    creation: {
        user: UserAccount["userInfo"] & { userId: string, isEliteCreator: boolean };
        timestamp: number;
        fromPlatform: "discord" | "telegram" | "alphaClub" | "discordAlphaClub" | "telegramAlphaClub";
    };
    content: {
        title: string;
        // chain: BoardPostChainInfo;
        asset: BoardPostAssetInfo;

        postType: "alpha" | "technicalAnalysis";
        position: "long" | "short";
        prediction: {
            alpha: {
                timeframe: { start: number, end: number };
            } | null,
            technicalAnalysis: {
                entryTarget: number;
                exitTargets: number[];
                advancedOptions: {
                    invalidationPoint: number | null,
                    timeframe: { start: number, end: number } | null;
                    taChartUrl: string | null;
                };
            } | null
        }

        research: string;
    },
    interactions: {
        upvote: { accounts: string[] },
        downvote: { accounts: string[] },
        totalVoteScore: number,
        comments: BoardPostCommentInfo[],
    },
    analysis: {
        toBeAnalyzed: boolean;
        result: BoardPostAnalysis | null,
    },
    id: string
}
// interface BoardPostChainInfo {
//     name: string,
//     iconUrl: string,
// };
// export interface BoardPostTokenInfo {
//     id: string;
//     symbol: string,
//     pairId: string;
//     chainId: number | null,
//     iconUrl: string,
//     bannerUrl?: string,
//     type: "coin" | "token" | "nft",
//     priceAtCreation: number,
// };
export interface BoardPostAssetInfo {
    info: TokenInfo | CoinInfo | NFTInfo;
    assetClass: AssetClass,
    type: "coin" | "token" | "nft",
    assetId: string,
    priceAtCreation: number,
};
interface BoardPostAnalysis {
    rating: number,
    pnl: number,
    timestamp: number,
}

export interface NewAlphaPostContent {
    title: string;
    // chain: BoardPostChainInfo | null;
    asset: BoardPostAssetInfo | null;

    postType: "alpha" | "technicalAnalysis" | null;
    position: "long" | "short";
    prediction: {
        alpha: {
            timeframe: { start: number | undefined, end: number | undefined };
        },
        technicalAnalysis: {
            entryTarget: number | undefined;
            exitTargets: number[];
            advancedOptions: {
                invalidationPoint: number | undefined;
                timeframe: { start: number | undefined, end: number | undefined };
                taChartUrl: string | null;
            };
        }
    }

    research: string;
}
export const defaultNewAlphaPostContent: NewAlphaPostContent = {
    title: "",
    // chain: null,
    asset: null,

    postType: null,
    position: "long",
    prediction: {
        alpha: {
            timeframe: { start: undefined, end: undefined },
        },
        technicalAnalysis: {
            entryTarget: undefined,
            exitTargets: [],
            advancedOptions: {
                invalidationPoint: undefined,
                timeframe: { start: undefined, end: undefined },
                taChartUrl: null,
            },
        },
    },

    research: "",
}


export interface BoardPostCommentInfo {
    creation: {
        user: UserAccount["userInfo"] & { userId: string, isEliteCreator: boolean };
        timestamp: number;
        deletionTimestamp: number | null,
        fromPlatform: "discord" | "telegram" | "alphaClub" | "discordAlphaClub" | "telegramAlphaClub";
    };
    content: {
        research: string;
        taChartUrl: string | null;
    } | null,
    // interactions: {
    //     upvote: { accounts: string[] },
    //     downvote: { accounts: string[] },
    // },
    replyInfo: { originBoardPostId: string, replyParentId: string } | null,
    id: string,
}

export enum PostInteraction {
    Upvote = "upvote",
    Downvote = "downvote",
}

export enum PostsQueryOrder {
    ASCENDING = "asc",
    DESCENDING = "desc",
}

export enum PostsQueryField {
    CreationTime = "creation.timestamp",
    TotalVotesScore = "interactions.totalVoteScore"
}

export interface PostFilters {
    asset: TokenInfo | null,
    position: Position,
    // startDate: number | null,
    // endDate: number | null,
    eliteCreatorOnly: boolean,
    queryField: PostsQueryField | undefined,
    queryOrder: PostsQueryOrder | undefined,
}

export enum Position {
    Long,
    Short,
    LongAndShort
}


// export interface TokenInfo {
// id: string;
// symbol: string;
// iconUrl: string;
// crypto: boolean;
// }

export interface SearchedTokensFetch {
    search: string,
    tokens: TokenInfo[],
    fetching: boolean,
}

export interface CoinInfo {
    id: string;
    symbol: string;
    iconUrl: string;
}
export interface TokenInfo {
    name: string;
    symbol: string;
    address: string;
    networkId: number;
    topPoolId: string;
    iconUrl: string;

    metrics?: {
        liquidity: string;
        price: number;
        priceChange: number;
        volume: string;
    };

    chain: {
        slug?: string,
        icon?: string,
    };
}
export interface NFTInfo {
    address: string;
    networkId: number;
    symbol: string;
    chain: {
        slug: string,
        icon: string,
    };
    pricingIcon: string;
    slug: string;
    iconUrl: string;
    bannerUrl: string;
}

export interface TokenPoolStats {
    price: number;
    priceChange24h: number;
    volume24h: number;
    liquidity: number;
    fdv: number;
    marketCap: number;
}

export enum AssetClass {
    Crypto = "crypto",
    NFT = "nft",
}

export enum PostCreationError {
    Title = "Provide a title",
    Research = "Write at least a few words",
    Asset = "Select an asset",
    TimeframeStart = "Select a start date",
    TimeframeEnd = "Select an end date",
    EntryTarget = "Provide an entry target",
    ExitTarget = " Provide an exit target",
    HighInvalidationOnLong = "Invalidation can not be higher than entry on long",
    LowInvalidationOnShort = "Invalidation can not be lower than entry on short",
    MissingPostEndPoint = "Missing either invalidation or timeframe",
    TaChartUrl = "Missing or incorrect url",
}
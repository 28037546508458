import "./AlphaPostsWidget.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import { BoardPostInfo } from "../../../utils/types";
import AlphaPostPreview from "../Components/AlphaPostPreview"
import ErrorContainer from "../../../Components/ErrorContainer";
import { PostsListFetch } from "../BigBoard";

// const boardPosts: BoardPostInfo[] = [
//     {
//         "id": "btc--fac29b22",
//         "analysis": {
//             "result": null,
//             "toBeAnalyzed": true
//         },
//         "creation": {
//             "user": {
//                 "isEliteCreator": false,
//                 "pfpUrl": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Telegram_2019_Logo.svg/480px-Telegram_2019_Logo.svg.png",
//                 "userId": "6cd92927-fe64-4491-9274-15ce79733978",
//                 "username": "JohnM1"
//             },
//             "timestamp": 1684718563201,
//             "fromPlatform": "alphaClub"
//         },
//         "content": {
//             "postType": "technicalAnalysis",
//             "prediction": {
//                 "alpha": null,
//                 "technicalAnalysis": {
//                     "entryTarget": 24222,
//                     "advancedOptions": {
//                         "taChartUrl": "https://www.tradingview.com/x/9un5l8GX/",
//                         "timeframe": null,
//                         "invalidationPoint": 0
//                     },
//                     "exitTargets": [
//                         26100
//                     ]
//                 }
//             },
//             "position": "long",
//             "title": "BTC ",
//             "research": "<p>BTC has been ranging at support for a good few days, normally this indicates distribution before a break down. </p><p><br></p><p>Debt ceiling drama is strong and if not resolved without delays then market will bleed and purge late longs, after all btc is almost up 80% in the year. </p><p><br></p><p>Meme season also indicates tops. </p><p><br></p><p>I was and still trying to be bullish short term before the melt, but this run window is closing more and more. day by day</p><p><br></p><p>If btc doesnt hold this current support and squeeze higher towards 30k one last time to bait all the plebs, then Im bidding around 24k for a scalp long. </p><p><br></p><p>current price action is bearish, current price structure is also bearish, funding is not really negative, quite neutral, OI has been too high, so basically not a good place to be opening positions right here, only with tight invalidations for both shorts and longs, cause next move will be strong.</p>",
//             "token": {
//                 "isCrypto": true,
//                 "symbol": "BTC",
//                 "chain": null,
//                 "priceAtCreation": 26667.349501634195,
//                 "id": "1",
//                 "iconUrl": "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png"
//             }
//         },
//         "interactions": {
//             "comments": [],
//             "downvote": {
//                 "accounts": []
//             },
//             "totalVoteScore": 0,
//             "upvote": {
//                 "accounts": []
//             }
//         }
//     },
//     {
//         "id": "unibot-iykyk-b32e9a28",
//         "analysis": {
//             "result": null,
//             "toBeAnalyzed": false
//         },
//         "content": {
//             "postType": "alpha",
//             "prediction": {
//                 "alpha": {
//                     "timeframe": {
//                         "start": 1684382400000,
//                         "end": 1684728000000
//                     }
//                 },
//                 "technicalAnalysis": null
//             },
//             "position": "long",
//             "title": "Unibot iykyk",
//             "research": "<p>Market cap at 800k, good token distribution, competitor which is maestro has 55k users and doesnt plan on launching a token</p><p>UNIBOT is free and pretends to improve the already solid tokenomics&nbsp;</p><p>its not a meme it has utility so thats the only problem lol jokes aside —&gt; read the following</p><p><br></p><p>The Fastest Telegram Uniswap&nbsp;</p><p>Mirror Sniper</p><p>Mirror snipes and frontrun buys of other traders. Create a list of trader wallets you wish to copy trade and start mirroring trades</p><p><br></p><p><br></p><p>Smart Method Sniper</p><p>Snipe token launches by entering a token contract. Use our ERC-20 launch scanner to see new tokens as they are deployed. Import the token to snipe through the scanner with a single button click.</p><p><br></p><p>Fast Multi-wallet Swaps</p><p>Buy and sell fast with multiple wallets simultaneously. Easily sell tokens by just selecting the token from a list.</p>",
//             "token": {
//                 "isCrypto": true,
//                 "chain": null,
//                 "symbol": "UNIBOT",
//                 "iconUrl": "https://pbs.twimg.com/profile_images/1658686598777810944/lCc11n-q_400x400.jpg",
//                 "id": "-1",
//                 "priceAtCreation": 1.1
//             }
//         },
//         "creation": {
//             "user": {
//                 "isEliteCreator": false,
//                 "pfpUrl": "https://pbs.twimg.com/profile_images/1628378236915777537/GGDX48s-_400x400.jpg",
//                 "userId": "b15ac792-b016-43c3-84fe-eb77316752cb",
//                 "username": "DonDeCastillon"
//             },
//             "fromPlatform": "alphaClub",
//             "timestamp": 1684384215000
//         },
//         "interactions": {
//             "comments": [
//                 {
//                     "creation": {
//                         "user": {
//                             "username": "klemenxina",
//                             "pfpUrl": "https://pbs.twimg.com/profile_images/1447587698521292801/1zMMp3SD_400x400.jpg",
//                             "isEliteCreator": false,
//                             "userId": "08204e94-abf4-4d9e-b72d-3b25a4e00768"
//                         },
//                         "timestamp": 1684429739256,
//                         "deletionTimestamp": null,
//                         "fromPlatform": "alphaClub"
//                     },
//                     "content": {
//                         "research": "<p>Imajin buying this</p>",
//                         "taChartUrl": null
//                     },
//                     "replyInfo": null,
//                     "id": "unibot-iykyk-b32e9a28-comment-84e378a4"
//                 }
//             ],
//             "downvote": {
//                 "accounts": [
//                     "08204e94-abf4-4d9e-b72d-3b25a4e00768"
//                 ]
//             },
//             "totalVoteScore": 0,
//             "upvote": {
//                 "accounts": [
//                     "b15ac792-b016-43c3-84fe-eb77316752cb"
//                 ]
//             }
//         }
//     },
//     {
//         "id": "btc-long-3aaa0561",
//         "analysis": {
//             "result": null,
//             "toBeAnalyzed": true
//         },
//         "creation": {
//             "user": {
//                 "isEliteCreator": false,
//                 "pfpUrl": "https://pbs.twimg.com/profile_images/1645838328090984459/NwHfx8dm_400x400.jpg",
//                 "userId": "1431de1a-1c60-4642-a9f3-45745c844529",
//                 "username": "CryptoPivot"
//             },
//             "timestamp": 1684253761692,
//             "fromPlatform": "alphaClub"
//         },
//         "content": {
//             "postType": "technicalAnalysis",
//             "prediction": {
//                 "alpha": null,
//                 "technicalAnalysis": {
//                     "entryTarget": 27285,
//                     "advancedOptions": {
//                         "taChartUrl": "https://www.tradingview.com/x/saKjCC15/",
//                         "timeframe": null,
//                         "invalidationPoint": 25000
//                     },
//                     "exitTargets": [
//                         28890
//                     ]
//                 }
//             },
//             "position": "long",
//             "title": "btc long",
//             "research": "<p>Could be putting in a nice rounded bottom here.  Have seen these play out with a quick green candle and nastily reject.  Have a monthly pivot to act as a nice absorption point for wither swing shorts or for consolidation and continued expansion</p>",
//             "token": {
//                 "isCrypto": true,
//                 "symbol": "BTC",
//                 "chain": null,
//                 "priceAtCreation": 27060.610465237856,
//                 "id": "1",
//                 "iconUrl": "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png"
//             }
//         },
//         "interactions": {
//             "comments": [],
//             "downvote": {
//                 "accounts": []
//             },
//             "totalVoteScore": 3,
//             "upvote": {
//                 "accounts": [
//                     "6cd92927-fe64-4491-9274-15ce79733978",
//                     "b15ac792-b016-43c3-84fe-eb77316752cb",
//                     "d04556ca-232f-4124-ae2e-325f8ed312d4"
//                 ]
//             }
//         }
//     },
//     {
//         "id": "fxs-long-idea-e1b08e5e",
//         "analysis": {
//             "result": null,
//             "toBeAnalyzed": true
//         },
//         "creation": {
//             "user": {
//                 "isEliteCreator": false,
//                 "pfpUrl": "https://pbs.twimg.com/profile_images/1645838328090984459/NwHfx8dm_400x400.jpg",
//                 "userId": "1431de1a-1c60-4642-a9f3-45745c844529",
//                 "username": "CryptoPivot"
//             },
//             "timestamp": 1684253350886,
//             "fromPlatform": "alphaClub"
//         },
//         "content": {
//             "postType": "technicalAnalysis",
//             "prediction": {
//                 "alpha": null,
//                 "technicalAnalysis": {
//                     "entryTarget": 7.33,
//                     "advancedOptions": {
//                         "taChartUrl": "https://www.tradingview.com/x/qt1Qr7Ke/",
//                         "timeframe": null,
//                         "invalidationPoint": 5.65
//                     },
//                     "exitTargets": [
//                         8.4
//                     ]
//                 }
//             },
//             "position": "long",
//             "title": "FXS Long Idea",
//             "research": "<p>Deviation and reclaim of range low as well as breaking the VAL.  Looking to take the first HVN and play with the range from there</p>",
//             "token": {
//                 "isCrypto": true,
//                 "symbol": "FXS",
//                 "chain": null,
//                 "priceAtCreation": 7.340066246750351,
//                 "id": "6953",
//                 "iconUrl": "https://s2.coinmarketcap.com/static/img/coins/64x64/6953.png"
//             }
//         },
//         "interactions": {
//             "downvote": {
//                 "accounts": []
//             },
//             "totalVoteScore": 3,
//             "upvote": {
//                 "accounts": [
//                     "6cd92927-fe64-4491-9274-15ce79733978",
//                     "b15ac792-b016-43c3-84fe-eb77316752cb",
//                     "d04556ca-232f-4124-ae2e-325f8ed312d4"
//                 ]
//             },
//             "comments": [
//                 {
//                     "creation": {
//                         "user": {
//                             "username": "klemenxina",
//                             "pfpUrl": "https://pbs.twimg.com/profile_images/1447587698521292801/1zMMp3SD_400x400.jpg",
//                             "isEliteCreator": false,
//                             "userId": "08204e94-abf4-4d9e-b72d-3b25a4e00768"
//                         },
//                         "timestamp": 1684429778866,
//                         "deletionTimestamp": null,
//                         "fromPlatform": "alphaClub"
//                     },
//                     "content": {
//                         "research": "<p>Too many Lines</p><p><br></p><p><br></p><p><br></p><p>Shit </p>",
//                         "taChartUrl": null
//                     },
//                     "replyInfo": null,
//                     "id": "fxs-long-idea-e1b08e5e-comment-addbcfa2"
//                 }
//             ]
//         }
//     },
//     {
//         "id": "we-long-strength-726bd902",
//         "analysis": {
//             "result": null,
//             "toBeAnalyzed": true
//         },
//         "creation": {
//             "user": {
//                 "isEliteCreator": false,
//                 "pfpUrl": "https://pbs.twimg.com/profile_images/1645838328090984459/NwHfx8dm_400x400.jpg",
//                 "userId": "1431de1a-1c60-4642-a9f3-45745c844529",
//                 "username": "CryptoPivot"
//             },
//             "timestamp": 1684252817055,
//             "fromPlatform": "alphaClub"
//         },
//         "content": {
//             "postType": "technicalAnalysis",
//             "prediction": {
//                 "alpha": null,
//                 "technicalAnalysis": {
//                     "entryTarget": 0.3673,
//                     "advancedOptions": {
//                         "taChartUrl": "https://www.tradingview.com/x/uLolL1xf/",
//                         "timeframe": null,
//                         "invalidationPoint": 0.3595
//                     },
//                     "exitTargets": [
//                         0.3831
//                     ]
//                 }
//             },
//             "position": "long",
//             "title": "We long strength",
//             "research": "<p>Joe has been outperforming and we long strength in a bear</p>",
//             "token": {
//                 "isCrypto": true,
//                 "symbol": "JOE",
//                 "chain": null,
//                 "priceAtCreation": 0.36865071436618785,
//                 "id": "11396",
//                 "iconUrl": "https://s2.coinmarketcap.com/static/img/coins/64x64/11396.png"
//             }
//         },
//         "interactions": {
//             "downvote": {
//                 "accounts": []
//             },
//             "totalVoteScore": 3,
//             "upvote": {
//                 "accounts": [
//                     "6cd92927-fe64-4491-9274-15ce79733978",
//                     "b15ac792-b016-43c3-84fe-eb77316752cb",
//                     "d04556ca-232f-4124-ae2e-325f8ed312d4"
//                 ]
//             },
//             "comments": [
//                 {
//                     "creation": {
//                         "user": {
//                             "username": "klemenxina",
//                             "pfpUrl": "https://pbs.twimg.com/profile_images/1447587698521292801/1zMMp3SD_400x400.jpg",
//                             "isEliteCreator": false,
//                             "userId": "08204e94-abf4-4d9e-b72d-3b25a4e00768"
//                         },
//                         "timestamp": 1684429832001,
//                         "deletionTimestamp": null,
//                         "fromPlatform": "alphaClub"
//                     },
//                     "content": {
//                         "research": "<p>How are you not banned</p>",
//                         "taChartUrl": null
//                     },
//                     "replyInfo": null,
//                     "id": "we-long-strength-726bd902-comment-824a9964"
//                 }
//             ]
//         }
//     },
//     {
//         "id": "fxs-the-next-ldo-2d127235",
//         "analysis": {
//             "result": null,
//             "toBeAnalyzed": true
//         },
//         "creation": {
//             "user": {
//                 "isEliteCreator": false,
//                 "pfpUrl": "https://pbs.twimg.com/profile_images/1266256547908251649/zHQnhZxE_400x400.jpg",
//                 "userId": "b4401ebf-e0b2-432a-b2e8-7105969043d1",
//                 "username": "80ssynthwave1"
//             },
//             "timestamp": 1684175541792,
//             "fromPlatform": "alphaClub"
//         },
//         "content": {
//             "postType": "technicalAnalysis",
//             "prediction": {
//                 "alpha": null,
//                 "technicalAnalysis": {
//                     "entryTarget": 7.163,
//                     "advancedOptions": {
//                         "taChartUrl": null,
//                         "timeframe": null,
//                         "invalidationPoint": 5.75
//                     },
//                     "exitTargets": [
//                         8.238
//                     ]
//                 }
//             },
//             "position": "long",
//             "title": "FXS the next ldo",
//             "research": "<p>LSDs back in the menu boysssss</p>",
//             "token": {
//                 "isCrypto": true,
//                 "symbol": "FXS",
//                 "chain": null,
//                 "priceAtCreation": 7.0592835314077025,
//                 "id": "6953",
//                 "iconUrl": "https://s2.coinmarketcap.com/static/img/coins/64x64/6953.png"
//             }
//         },
//         "interactions": {
//             "comments": [],
//             "downvote": {
//                 "accounts": []
//             },
//             "totalVoteScore": 1,
//             "upvote": {
//                 "accounts": [
//                     "b15ac792-b016-43c3-84fe-eb77316752cb"
//                 ]
//             }
//         }
//     }
// ]

const AlphaPostsWidget: FC<any> = (props) => {
    const { postsListFetch, fetchBoardPosts, interactWithPost, onPostClick, toggleSavePost }: { postsListFetch: PostsListFetch, fetchBoardPosts: Function, interactWithPost: any, onPostClick: Function, toggleSavePost: Function } = props;

    // assign a random number between 250 and 450 to the height of each loading square
    const loadingSquareHeights: number[] = [];
    for (let i = 0; i < 3; i++) {
        loadingSquareHeights.push(Math.random() * 200 + 250);
    }

    return (
        <div id="alpha-posts-widget" className="d-flex flex-column alpha-club-widget">
            {postsListFetch.boardPosts.map(post => <AlphaPostPreview key={post.id} post={post} interactWithPost={interactWithPost} onPostClick={onPostClick} toggleSavePost={toggleSavePost} />)}
            {postsListFetch.fetching ? <>
                <div className="loading-square alpha-post-preview-loading my-2" style={{ height: loadingSquareHeights[0] }}></div>
                <div className="loading-square alpha-post-preview-loading my-2" style={{ height: loadingSquareHeights[1] }}></div>
                <div className="loading-square alpha-post-preview-loading my-2" style={{ height: loadingSquareHeights[2] }}></div>
            </> : null
            }
            {!postsListFetch.fetching && postsListFetch.boardPosts.length === 0 && postsListFetch.allFetched ? <div className="text-center mt-4">No posts to show.</div> : null}
            {postsListFetch.fetchError && <ErrorContainer text="An error occured while fetching recent posts." buttonText="Try again" onClick={() => fetchBoardPosts(undefined, false)} className="mt-4" />}
        </div>
    )
};

export default AlphaPostsWidget;
import "./TokenSelectorDropdown.css";
import React, { FC, useEffect, useState, useRef, useMemo } from "react";

import Dropdown from "react-bootstrap/Dropdown"
import { LazyLoadImage } from 'react-lazy-load-image-component';

// import { supportedTokensArray, suppportedNftProjectsArray } from "../utils/supportedTokens"
import { TokenInfo, SearchedTokensFetch, AssetClass } from "../utils/types";
import { DEFINED_TOKEN_ICON_URL } from "../utils/utils";
import { CHAIN_SLUGS } from "../utils/constants";
import api from "../utils/api/api";

import { ReactComponent as CaretUp } from "../Images/Icons/CaretUp.svg";
import { ReactComponent as CaretDown } from "../Images/Icons/CaretDown.svg";
import { ReactComponent as MagnifyinGlass } from "../Images/Icons/MagnifyinGlass.svg";

const TokenSelectorDropdown: FC<any> = (props) => {
    const { className, buttonClassName, assetClass, selectedToken, setSelectedToken } = props;

    const [tokenSearch, setTokenSearch] = useState("");
    const [searchedTokensFetch, setSearchedTokensFetch] = useState<SearchedTokensFetch>({ search: "", tokens: [], fetching: false });

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            console.log(tokenSearch)
            searchForTokens()
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [tokenSearch])

    async function searchForTokens() {
        if (!tokenSearch.length) {
            setSearchedTokensFetch({ ...searchedTokensFetch, tokens: [] })
            return;
        }
        setSearchedTokensFetch({ ...searchedTokensFetch, fetching: true })
        const searchTokensResult = await api.defi.searchToken(tokenSearch, 10);
        // const searchTokensResult = [
        //     {
        //         "name": "Ethereum",
        //         "symbol": "ETH",
        //         "address": "0x74b23882a30290451a17c44f4f05243b6b58c76d",
        //         "networkId": 250,
        //         "topPairId": "0xf0702249f4d3a25cd3ded7859a165693685ab577:250",
        //         "liquidity": "4676353",
        //         "price": 1657.27996338,
        //         "priceChange": 0.07636032117731648,
        //         "volume": "2231065"
        //     },
        //     {
        //         "name": "Ethereum Token",
        //         "symbol": "ETH",
        //         "address": "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
        //         "networkId": 56,
        //         "topPairId": "0x0f338ec12d3f7c3d77a4b9fcc1f95f3fb6ad0ea6:56",
        //         "liquidity": "707165",
        //         "price": 1938.10806682,
        //         "priceChange": -0.02845736670730668,
        //         "volume": "1413873"
        //     },
        //     {
        //         "name": "ETH via channel 0",
        //         "symbol": "ETH",
        //         "address": "0x5fd55a1b9fc24967c4db09c513c3ba0dfa7ff687",
        //         "networkId": 7700,
        //         "topPairId": "0x216400ba362d8fce640085755e47075109718c8b:7700",
        //         "liquidity": "2912292",
        //         "price": 1940.85108274,
        //         "priceChange": -0.034501050379978575,
        //         "volume": "1110195"
        //     },
        //     {
        //         "name": "Ethereum",
        //         "symbol": "ETH",
        //         "address": "0x639a647fbe20b6c8ac19e48e2de44ea792c62c5c",
        //         "networkId": 1285,
        //         "topPairId": "0x0d171b55fc8d3bddf17e376fdb2d90485f900888:1285",
        //         "liquidity": "358663",
        //         "price": 1950.98547461,
        //         "priceChange": -0.02647125890069127,
        //         "volume": "100350"
        //     },
        //     {
        //         "name": "KCC-Peg Ether",
        //         "symbol": "ETH",
        //         "address": "0xf55af137a98607f7ed2efefa4cd2dfe70e4253b1",
        //         "networkId": 321,
        //         "topPairId": "0x1f1ec5bcb2cb2a892d34a85d3ecb4c7e167ff630:321",
        //         "liquidity": "1427254",
        //         "price": 1940.04425409,
        //         "priceChange": -0.025452152434812022,
        //         "volume": "52404"
        //     },
        //     {
        //         "name": "Heco-Peg ETH Token",
        //         "symbol": "ETH",
        //         "address": "0x64ff637fb478863b7468bc97d30a5bf3a428a1fd",
        //         "networkId": 128,
        //         "topPairId": "0x78c90d3f8a64474982417cdb490e840c01e516d4:128",
        //         "liquidity": "331296",
        //         "price": 1939.61341785,
        //         "priceChange": -0.026555229270121772,
        //         "volume": "30880"
        //     },
        //     {
        //         "name": "RonWeasleyGenslerSpyro11Inu",
        //         "symbol": "ETH",
        //         "address": "0xdd1a646123855564af8e44812c2cdb1f485412de",
        //         "networkId": 1,
        //         "topPairId": "0xfdefc3d9517b1084f25e31d75cc87f79559f50e7:1",
        //         "liquidity": "8691",
        //         "price": 0.000041563820123,
        //         "priceChange": 1.7916098303711154,
        //         "volume": "24140"
        //     },
        //     {
        //         "name": "Ethereum",
        //         "symbol": "ETH",
        //         "address": "0xfbdf0e31808d0aa7b9509aa6abc9754e48c58852",
        //         "networkId": 53935,
        //         "topPairId": "0x79724b6996502afc773feb3ff8bb3c23adf2854b:53935",
        //         "liquidity": "313332",
        //         "price": 1962.2660888,
        //         "priceChange": -0.016359065775779773,
        //         "volume": "17953"
        //     },
        //     {
        //         "name": "Ethereum",
        //         "symbol": "ETH",
        //         "address": "0xfa9343c3897324496a05fc75abed6bac29f8a40f",
        //         "networkId": 40,
        //         "topPairId": "0xea4f6c46c09b0cf20716a0047da3a1dae5b01965:40",
        //         "liquidity": "18830",
        //         "price": 1622.10306035,
        //         "priceChange": -0.39536233347598504,
        //         "volume": "14640"
        //     },
        //     {
        //         "name": "Ethereum",
        //         "symbol": "ETH",
        //         "address": "0xe3f5a90f9cb311505cd691a46596599aa1a0ad7d",
        //         "networkId": 2001,
        //         "topPairId": "0x11f34aa0648b8bcb805ae69bf0063bb900c99ab7:2001",
        //         "liquidity": "23473",
        //         "price": 4565.6222675,
        //         "priceChange": 0.5026697195901466,
        //         "volume": "9481"
        //     }
        // ]
        const searchedTokens: TokenInfo[] = []
        for (const tokenResult of searchTokensResult) {
            searchedTokens.push({
                name: tokenResult.name,
                symbol: tokenResult.symbol,
                address: tokenResult.address,
                networkId: tokenResult.networkId,
                topPoolId: tokenResult.topPairId.split(":")[0],
                iconUrl: DEFINED_TOKEN_ICON_URL(tokenResult),

                metrics: {
                    liquidity: tokenResult.liquidity,
                    price: tokenResult.price,
                    priceChange: tokenResult.priceChange,
                    volume: tokenResult.volume,
                },

                chain: { slug: CHAIN_SLUGS[tokenResult.networkId], icon: `https://icons.llamao.fi/icons/chains/rsz_${CHAIN_SLUGS[tokenResult.networkId]}.jpg` }
            })
        }
        console.log(searchedTokens)
        setSearchedTokensFetch({ search: tokenSearch, tokens: searchedTokens, fetching: false })
    }


    return (
        <Dropdown className={`filter-dropdown select-token-dropdown ${className}`}>
            <Dropdown.Toggle variant="dark" className={buttonClassName}>
                {!selectedToken ? `Select ${assetClass === AssetClass.Crypto ? "Token" : "Project"}` : <><img src={DEFINED_TOKEN_ICON_URL(selectedToken)} className="token-icon me-2" /> {selectedToken.name ?? selectedToken.symbol}</>}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {/* <Dropdown.Item onClick={() => setSelectedToken(null)}>Select {assetClass === TokenType.Crypto ? "Token" : "Project"}</Dropdown.Item> */}
                <div className="select-token-dropdown-filter d-flex px-3 w-100">
                    <MagnifyinGlass className="select-token-dropdown-filter-icon ps-1" />
                    <input type="text" placeholder="Search token" className="select-token-dropdown-filter-input ps-2" value={tokenSearch} onChange={(e) => setTokenSearch(e.target.value)} />
                </div>
                <div className="scrollable-dropdown-menu" >
                    {searchedTokensFetch.fetching ? "Loading" :
                        searchedTokensFetch.tokens.map((tokenInfo, index) => {
                            return (
                                <Dropdown.Item key={index} onClick={() => setSelectedToken(tokenInfo)}>
                                    <div className="d-flex align-items-center">
                                        <LazyLoadImage src={DEFINED_TOKEN_ICON_URL(tokenInfo)} className="token-icon me-2" />
                                        <div className="d-flex justify-content-start align-items-center w-100">
                                            <div className="token-symbol" style={{ width: "20%" }}>{tokenInfo.symbol}</div>
                                            {tokenInfo.chain.icon ? <LazyLoadImage src={tokenInfo.chain.icon} className="token-icon me-2" /> : ""}
                                            <div className="token-stats d-flex ms-auto">
                                                <div className="token-price me-2">${tokenInfo.metrics?.price?.toFixed(2) ?? "-"}</div>
                                                {tokenInfo.metrics?.priceChange !== undefined ? <div className={`token-price-change ${tokenInfo.metrics?.priceChange >= 0 ? "text-selection" : "text-error"}`}>
                                                    {tokenInfo.metrics?.priceChange > 0 ? <CaretUp /> : <CaretDown />}
                                                    <span className="ms-1">{Math.abs(tokenInfo.metrics?.priceChange).toFixed(2)}%</span>
                                                </div> : "-"}
                                            </div>
                                        </div>
                                    </div>
                                </Dropdown.Item>
                            )
                        })
                    }
                </div>
            </Dropdown.Menu>
        </Dropdown >
    )
}

export default TokenSelectorDropdown;

import boardPost from "./routes/boardPost"
import marketNews from "./routes/marketNews"

import defi from "./routes/defi";

import auth from "./auth/auth"

// export const API_URL = "http://localhost:3001/v1";
export const API_URL = "https://us-central1-wolves-capital.cloudfunctions.net/api/v1";

export default {
    boardPost,
    marketNews,

    defi,

    auth
}
import "./AlphaPostViewerModal.css"
import React, { FC, useEffect, useState, useRef, useReducer } from "react";
import { useHistory } from "react-router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faArrowTrendUp, faArrowTrendDown, faChessKnight, faTrash, faArrowUpFromBracket, faLink, faBookmark } from '@fortawesome/free-solid-svg-icons'
import { faBookmark as faRegularBookmark } from '@fortawesome/free-regular-svg-icons'
import { faEthereum, faTwitter } from "@fortawesome/free-brands-svg-icons"

import { AdvancedChart } from "react-tradingview-embed";

import { LazyLoadImage } from 'react-lazy-load-image-component';

import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import AlphaPostCommentWriter from "./AlphaPostCommentWriter";
import AlphaPostComment from "./AlphaPostComment"
import ErrorContainer from "../../../Components/ErrorContainer"
import AddressClicker from "../../../Components/AddressClicker";

import EthIcon from "../../../Images/Icons/ethereumIcon.png";
import { ReactComponent as CaretUp } from "../../../Images/Icons/CaretUp.svg";
import { ReactComponent as CaretDown } from "../../../Images/Icons/CaretDown.svg";

import { SelectedPostFetch } from "../BigBoard"
import api from "../../../utils/api/api"
import { timestampToSmallDate, timestampToRelative, timestampToClockTime, getFullBaseUrl, delay, animateLoadingSquares, numberWithCommas, getRoundedPrice, arrangePostComments, formatNumberForPrice } from "../../../utils/utils"
import { PostInteraction, BoardPostCommentInfo, CoinInfo, TokenInfo, NFTInfo, AssetClass, TokenPoolStats } from "../../../utils/types"
import { useUser } from "../../../Components/Connection/useUser"
import { MAIN_WEBSITE_URL, MAX_TIME_TO_DELETE_ALPHA_POST } from "../../../utils/constants";

import createDOMPurify from "dompurify";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";
const purify = createDOMPurify(window);

interface PostTokenPoolStatsFetch {
    tokenPoolStats: TokenPoolStats | null,
    fetching: boolean,
}

const AlphaPostViewerModal: FC<any> = (props) => {
    const { assetClass, searchedBoardPostId, selectedPostFetch, setSelectedPostFetch, interactWithPost, removePostFromPostsList, toggleSavePost }: { assetClass: AssetClass, searchedBoardPostId: string, selectedPostFetch: SelectedPostFetch, setSelectedPostFetch: Function, interactWithPost: Function, removePostFromPostsList: Function, toggleSavePost: Function } = props;

    const history = useHistory()

    const { currentUser } = useUser();
    const sharePostButtonRef = useRef<HTMLButtonElement>(null);

    const [postTokenPoolStatsFetch, setPostTokenPoolStatsFetch] = useState<PostTokenPoolStatsFetch>({ tokenPoolStats: null, fetching: false })
    const [commentReplyTarget, setCommentReplyTarget] = useState<{ originCommentId: string, id: string } | null>(null)
    const [selectedPostTimeLeftToDelete, setSelectedPostTimeLeftToDelete] = useState<number>()

    useEffect(() => {
        if (searchedBoardPostId !== selectedPostFetch.boardPost?.id) {
            fetchBoardPostInfo(searchedBoardPostId);
        }
    }, [searchedBoardPostId])

    useEffect(() => {
        if (searchedBoardPostId !== undefined || assetClass === undefined) return;
        if (selectedPostFetch.boardPost === null) {
            // history.push(`/big-board/${assetClass.valueOf()}`)
            return;
        }
        history.push(`/big-board/${assetClass.valueOf()}/${selectedPostFetch.boardPost.id}`);
    }, [selectedPostFetch.boardPost])

    useEffect(() => {
        if (selectedPostFetch.boardPost === null || selectedPostFetch.boardPost?.content.asset.type !== "token") return;
        fetchPostTokenPoolStats()
        const fetchTokenPoolStatsInterval = setInterval(() => fetchPostTokenPoolStats(false), 30000);
        return () => clearInterval(fetchTokenPoolStatsInterval);
    }, [selectedPostFetch.boardPost?.content.asset])

    async function fetchBoardPostInfo(boardPostId: string) {
        setSelectedPostFetch({ ...selectedPostFetch, fetching: true, fetchError: false });
        const getPostInfoPromise = api.boardPost.getPostsInfo([boardPostId]);
        animateLoadingSquares("alpha-post-modal", getPostInfoPromise)
        const response = await getPostInfoPromise
        if (!response.success) {
            setSelectedPostFetch({ ...selectedPostFetch, fetching: false, fetchError: true });
            return
        }
        let boardPostInfo;
        if (response.boardPosts !== null) boardPostInfo = arrangePostComments(response.boardPosts[0]);
        console.log(boardPostInfo)
        setSelectedPostFetch({ ...selectedPostFetch, fetching: false, fetchError: false, boardPost: boardPostInfo });
    }

    async function fetchPostTokenPoolStats(indicateFetch: boolean = true) {
        console.log("fetching token pool stats")
        if (selectedPostFetch.boardPost === null || selectedPostFetch.boardPost?.content.asset.type !== "token") return;
        setPostTokenPoolStatsFetch(prevPostTokenPoolStatsFetch => ({ ...prevPostTokenPoolStatsFetch, fetching: indicateFetch }));
        const getPostTokenPoolStatsPromise = api.defi.getTokenPoolStats(selectedPostFetch.boardPost?.content.asset.info as TokenInfo);
        if (indicateFetch) animateLoadingSquares("alpha-post-token-pool-stats", getPostTokenPoolStatsPromise);
        const response = await getPostTokenPoolStatsPromise;
        setPostTokenPoolStatsFetch({ tokenPoolStats: response, fetching: false });
    }



    useEffect(() => {
        updatePostTimeLeftToDelete();
    }, [selectedPostFetch.boardPost, currentUser, selectedPostTimeLeftToDelete])
    async function updatePostTimeLeftToDelete() {
        if (selectedPostFetch.boardPost !== null && selectedPostFetch.boardPost.creation.user.userId === currentUser?.userId) {
            if (selectedPostTimeLeftToDelete !== 0 && selectedPostTimeLeftToDelete !== undefined) await delay(1000)
            setSelectedPostTimeLeftToDelete(Math.max(selectedPostFetch.boardPost.creation.timestamp + MAX_TIME_TO_DELETE_ALPHA_POST - Date.now(), 0))
        } else {
            setSelectedPostTimeLeftToDelete(0)
        }
    }

    function closeSelectedPost() {
        history.push(`/big-board/${assetClass.valueOf()}`)
        setSelectedPostFetch({ ...selectedPostFetch, boardPost: null, fetching: false, fetchError: false, deleting: false });
    }

    async function tryDeleteSelectedPost() {
        if (currentUser === null || selectedPostFetch.boardPost === null) return;
        if (selectedPostFetch.boardPost?.creation.user.userId !== currentUser?.userId || selectedPostTimeLeftToDelete === undefined || selectedPostTimeLeftToDelete <= 0) return;
        setSelectedPostFetch({ ...selectedPostFetch, deleting: true, fetchError: false });
        mixpanel.track("Post: Delete", {
            timeLeftToDelete: selectedPostTimeLeftToDelete,
            postCreatorIsEliteMember: selectedPostFetch.boardPost?.creation.user.isEliteCreator,
        })

        const success = await api.boardPost.deletePost(selectedPostFetch.boardPost.id);
        if (success) {
            removePostFromPostsList(selectedPostFetch.boardPost.id)
            closeSelectedPost();
            return;
        }
        setSelectedPostFetch({ ...selectedPostFetch, deleting: false, fetchError: false });
    }

    async function deleteComment(commentId: string) {
        if (currentUser === null || selectedPostFetch.boardPost === null) return;
        mixpanel.track("Comment: Delete")

        const response = await api.boardPost.deleteComment(selectedPostFetch.boardPost.id, commentId, undefined);
        if (!response.success) {
            toast.error(response.error);
        }
        removeCommentFromBoardPostBaseComments(commentId);
    }

    function addCommentToBoardPostBaseComments(boardPostCommentInfo: BoardPostCommentInfo) {
        if (selectedPostFetch.boardPost === null) return;
        setSelectedPostFetch({ ...selectedPostFetch, boardPost: { ...selectedPostFetch.boardPost, interactions: { ...selectedPostFetch.boardPost.interactions, comments: [...selectedPostFetch.boardPost.interactions.comments, boardPostCommentInfo] } } })
    }
    function removeCommentFromBoardPostBaseComments(commentId: string) {
        if (selectedPostFetch.boardPost === null) return;
        const newComments = selectedPostFetch.boardPost.interactions.comments.map((comment: BoardPostCommentInfo) => {
            if (comment.id === commentId) comment.content = null;
            return comment;
        });
        setSelectedPostFetch({ ...selectedPostFetch, boardPost: { ...selectedPostFetch.boardPost, interactions: { ...selectedPostFetch.boardPost.interactions, comments: newComments } } })
    }

    const tradingViewChart = React.useMemo(() =>
        <div>
            {/* {selectedPostFetch.boardPost !== null && selectedPostFetch.boardPost.content.token !== null && <iframe height="100%" width="100%" id="geckoterminal-embed" title="GeckoTerminal Embed" src={`https://www.geckoterminal.com/eth/pools/${}?embed=1&info=1&swaps=1`} frameBorder="0" allow="clipboard-write" allowFullScreen></iframe>} */}
            {selectedPostFetch.boardPost !== null ?
                selectedPostFetch.boardPost.content.asset.type === "coin" ? selectedPostFetch.boardPost.content.asset !== null && <AdvancedChart widgetProps={{ "theme": "dark", width: "100%", height: "500px", style: "1", symbol: `${(selectedPostFetch.boardPost.content.asset.info as CoinInfo).symbol}USD`, allow_symbol_change: false }} />
                    : selectedPostFetch.boardPost.content.asset.type === "token" ? <iframe height="500px" width="100%" id="geckoterminal-embed" title="GeckoTerminal Embed" src={`https://www.geckoterminal.com/eth/pools/${(selectedPostFetch.boardPost.content.asset.info as TokenInfo).address}?embed=1&info=0&swaps=0`} frameBorder="0" allow="clipboard-write" allowFullScreen></iframe>
                        : "" : ""
            }
            {/* {selectedPostFetch.boardPost.content.token !== null && <AdvancedRealTimeChart theme="dark" width="100%" height="500px" symbol={`${selectedPostFetch.boardPost.content.token.symbol}USD`} allow_symbol_change={false} />} */}
        </div>, [selectedPostFetch.boardPost?.content.asset]);

    const commentsContainer = React.useMemo(() =>
        <div id="alpha-post-comments" className="d-flex flex-column mt-5">
            {selectedPostFetch.boardPost?.interactions.comments.map((boardPostCommentInfo: BoardPostCommentInfo) => <AlphaPostComment key={boardPostCommentInfo.id} tradingViewChart={tradingViewChart} selectedPostFetch={selectedPostFetch} boardPostCommentInfo={boardPostCommentInfo} className="mb-3 mb-md-4 mb-lg-5" deleteComment={deleteComment} commentReplyTarget={commentReplyTarget} setCommentReplyTarget={setCommentReplyTarget} />)}
        </div>, [selectedPostFetch.boardPost?.interactions.comments, commentReplyTarget]);

    const boardPostPredictionTimeRange = selectedPostFetch.boardPost === null ? null : selectedPostFetch.boardPost.content.postType === "alpha" ? selectedPostFetch.boardPost.content.prediction.alpha!.timeframe : selectedPostFetch.boardPost.content.prediction.technicalAnalysis!.advancedOptions.timeframe;

    const currencySpan = <span className="currency-icon">{selectedPostFetch.boardPost?.content.asset.type !== "nft" ? "$" : <img src={(selectedPostFetch.boardPost?.content.asset.info as NFTInfo).pricingIcon} />}</span>

    return (
        <Modal id="alpha-post-modal" size="lg" centered show={searchedBoardPostId !== undefined || selectedPostFetch.fetching || selectedPostFetch.boardPost !== null} onHide={closeSelectedPost}>
            <Modal.Header closeButton>
                {(selectedPostFetch.fetching || selectedPostFetch.boardPost !== null) && <Modal.Title className="header-gradient text-bold">{selectedPostFetch.boardPost === null ? <div className="loading-square" style={{ width: "300px", height: "45px" }}></div> : selectedPostFetch.boardPost.content.title}</Modal.Title>}
            </Modal.Header>
            <Modal.Body className="d-flex flex-column p-2 p-sm-3">
                {!selectedPostFetch.fetching && selectedPostFetch.fetchError ? <ErrorContainer text="Could not load post." buttonText="Try again" onClick={() => fetchBoardPostInfo(searchedBoardPostId)} className="mt-5" />
                    : <div id="selected-alpha-post" className="d-flex flex-column">
                        <div className="d-flex">
                            <div className="alpha-post-interactions d-none d-md-flex flex-column align-items-center">
                                <CaretUp className={`svg-inline--fa fa-caret-up hover-pointer fa-2x ${currentUser !== null && selectedPostFetch.boardPost?.interactions.upvote.accounts.includes(currentUser.userId) ? "text-selection" : ""}`} onClick={() => selectedPostFetch.boardPost !== null && interactWithPost(selectedPostFetch.boardPost, PostInteraction.Upvote)} />
                                {selectedPostFetch.boardPost === null ? <div className="loading-square" style={{ width: "50px", height: "25px" }}></div> : selectedPostFetch.boardPost.interactions.upvote.accounts.length - selectedPostFetch.boardPost.interactions.downvote.accounts.length}
                                <CaretDown className={`svg-inline--fa fa-caret-down hover-pointer fa-2x ${currentUser !== null && selectedPostFetch.boardPost?.interactions.downvote.accounts.includes(currentUser.userId) ? "text-error" : ""}`} onClick={() => selectedPostFetch.boardPost !== null && interactWithPost(selectedPostFetch.boardPost, PostInteraction.Downvote)} />

                                {selectedPostFetch.boardPost !== null && <div className="d-flex flex-column align-items-center mt-3">
                                    <OverlayTrigger
                                        trigger={["focus"]}
                                        placement="right"
                                        overlay={
                                            <Popover id="share-post-popover" onClick={(e) => sharePostButtonRef.current?.focus()}>
                                                <Popover.Body>
                                                    <div className="d-flex align-items-center mb-2">
                                                        <AddressClicker address={`${getFullBaseUrl(window)}/big-board/${selectedPostFetch.boardPost.id}`} component={<span><FontAwesomeIcon icon={faLink} style={{ width: "20px", marginRight: ".8rem" }} /> Copy link to post</span>} />
                                                    </div>
                                                    <div className="d-flex align-items-center mt-2">
                                                        <a style={{ textDecoration: "none", color: "black" }} href={`https://twitter.com/intent/tweet?text=${selectedPostFetch.boardPost.content.title.replaceAll(" ", "%20")}%0A%0ANew%20alpha%20post%20on%20Wolves%20Capital%20Alpha%20Club&url=${getFullBaseUrl(window, true)}%2Fbig-board%2F${selectedPostFetch.boardPost.id}`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} style={{ width: "20px", marginRight: ".8rem" }} /> Share on Twitter</a>
                                                    </div>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <button className="btn-simple" ref={sharePostButtonRef} onClick={(e) => sharePostButtonRef.current?.focus()}><FontAwesomeIcon icon={faArrowUpFromBracket} /></button>
                                    </OverlayTrigger>
                                    <button className="btn-simple mt-2" onClick={() => toggleSavePost(selectedPostFetch.boardPost)}><FontAwesomeIcon icon={currentUser?.userAlphaInfo[selectedPostFetch.boardPost.content.asset.assetClass].savedPostsIds.includes(selectedPostFetch.boardPost.id) ? faBookmark : faRegularBookmark} /></button>
                                </div>}
                            </div>
                            <div className="alpha-post-info d-flex flex-column w-100 ps-md-3">
                                <div className="alpha-post-details d-flex align-items-center text-normal mt-2">
                                    <div className="d-flex align-items-center token-info ms-1 me-3 my-1">
                                        {selectedPostFetch.boardPost === null ? <div className="loading-square" style={{ width: "90px" }}></div> : <a className="simple-anchor" target="_blank" rel="noopener noreferrer" href={selectedPostFetch.boardPost.content.asset.type === "coin" ? `https://www.tradingview.com/symbols/${selectedPostFetch.boardPost.content.asset.info.symbol}USDC/` : selectedPostFetch.boardPost.content.asset.type === "token" ? `https://www.geckoterminal.com/eth/pools/${(selectedPostFetch.boardPost.content.asset.info as TokenInfo).topPoolId}` : `https://opensea.io/collection/${(selectedPostFetch.boardPost.content.asset.info as NFTInfo).slug}`}><img src={selectedPostFetch.boardPost.content.asset.info.iconUrl} className="token-icon me-2" /> {selectedPostFetch.boardPost.content.asset.info.symbol} ({numberWithCommas(getRoundedPrice(selectedPostFetch.boardPost.content.asset.priceAtCreation))} {currencySpan})</a>}
                                    </div>
                                    <div className={`d-flex align-items-center price-range-info ${selectedPostFetch.boardPost?.content.position === "long" ? "uptrend" : "downtrend"} mx-2 my-1 order-last`}>
                                        {/* {selectedPostFetch.boardPost === null ? <div className="loading-square" style={{ width: "90px" }}></div> : <span><FontAwesomeIcon icon={selectedPostFetch.boardPost.content.position === "long" ? faArrowTrendUp : faArrowTrendDown} className="me-2" /> {selectedPostFetch.boardPost.content.postType === "technicalAnalysis" ? numberWithCommas(getRoundedPrice(selectedPostFetch.boardPost.content.prediction.technicalAnalysis!.exitTargets[0])) + "$" : ""}</span>} */}
                                        {selectedPostFetch.boardPost === null ? <div className="loading-square" style={{ width: "90px" }}></div> :
                                            <div className="d-flex align-items-center">
                                                {selectedPostFetch.boardPost.content.postType === "technicalAnalysis" ? <span className="text-white">{numberWithCommas(getRoundedPrice(selectedPostFetch.boardPost.content.prediction.technicalAnalysis!.entryTarget))} {currencySpan}</span> :
                                                    ""}
                                                <FontAwesomeIcon icon={selectedPostFetch.boardPost.content.position === "long" ? faArrowTrendUp : faArrowTrendDown} className={`${selectedPostFetch.boardPost.content.position === "long" ? "uptrend" : "downtrend"} mx-2`} />
                                                <span className={selectedPostFetch.boardPost.content.position === "long" ? "uptrend" : "downtrend"}>
                                                    {selectedPostFetch.boardPost!.content.postType === "technicalAnalysis" ? <>{numberWithCommas(getRoundedPrice(selectedPostFetch.boardPost.content.prediction.technicalAnalysis!.exitTargets[0]))} {currencySpan}</> :
                                                        ""}
                                                </span>
                                            </div>
                                        }
                                    </div>
                                    {boardPostPredictionTimeRange !== null && <div className="d-flex align-items-center time-range-info mx-2 my-1 order-md-last">
                                        {selectedPostFetch.boardPost === null ? <div className="loading-square" style={{ width: "90px" }}></div> : <span><FontAwesomeIcon icon={faClock} className="me-2" /> {timestampToSmallDate(boardPostPredictionTimeRange.start / 1000)} - {timestampToSmallDate(boardPostPredictionTimeRange.end / 1000)}</span>}
                                    </div>}
                                    {
                                        selectedPostFetch.boardPost?.content.postType === "technicalAnalysis" && selectedPostFetch.boardPost?.content.prediction.technicalAnalysis!.advancedOptions?.invalidationPoint !== undefined && <div className="d-flex ps-3 order-last" style={{ fontSize: "0.9rem" }}>
                                            <i>Invalidation Point: {selectedPostFetch.boardPost.content.prediction.technicalAnalysis.advancedOptions.invalidationPoint} {currencySpan}</i>
                                        </div>
                                    }
                                </div>
                                {/* <div className="post-trade-details d-flex">
                                    <div className="d-flex align-items-center token-info ms-2 me-3 my-1 order-first">
                                        <img src={post.content.token.iconUrl} className="token-icon me-2" /> {post.content.token.symbol} <span className="price-at-creation ms-2">({numberWithCommas(getRoundedPrice(post.content.token.priceAtCreation))}$)</span>
                                    </div>
                                    <div className={`d-flex align-items-center price-range-info ${post.content.position === "long" ? "uptrend" : "downtrend"} mx-2 my-1 order-last`}>
                                        {post.content.postType === "technicalAnalysis" ? <span className="text-white">{numberWithCommas(getRoundedPrice(post.content.prediction.technicalAnalysis!.entryTarget))}$</span> : ""} <FontAwesomeIcon icon={post.content.position === "long" ? faArrowTrendUp : faArrowTrendDown} className="mx-2" /> {post.content.postType === "technicalAnalysis" ? numberWithCommas(getRoundedPrice(post.content.prediction.technicalAnalysis!.exitTargets[0])) + "$" : ""}
                                    </div>
                                    {boardPostPredictionTimeRange !== null ? <div className="d-flex align-items-center time-range-info mx-2 my-1 order-md-last">
                                        <FontAwesomeIcon icon={faClock} className="me-2" /> {timestampToSmallDate(boardPostPredictionTimeRange.start / 1000)} - {timestampToSmallDate(boardPostPredictionTimeRange.end / 1000)}
                                    </div> : null}
                                </div> */}
                                <h4 className="alpha-post-title text-header">{selectedPostFetch.boardPost === null ? <div className="loading-square mx-0" style={{ width: "250px" }}></div> : selectedPostFetch.boardPost.content.title} {selectedPostFetch.boardPost?.creation.user.isEliteCreator && <span title="Elite Club Member"><FontAwesomeIcon icon={faChessKnight} className="elite-member-icon" style={{ fontSize: "1.3rem" }} /></span>}</h4>

                                {
                                    selectedPostFetch.boardPost?.content.asset.type === "token" ? <div id="alpha-post-token-pool-stats" className="d-flex align-items-center px-4 py-2 mt-2 mb-3">
                                        <div className="d-flex flex-column">
                                            {/* <h5 className="pool-stat-header mb-0">Price</h5> */}
                                            <div className="d-flex align-items-center">
                                                {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "160px", height: "45px" }}></div> : <>
                                                    <h2 className="mb-0">{postTokenPoolStatsFetch.tokenPoolStats?.price ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.price)}` : "-"}</h2>
                                                    <h6 className={`ms-2 mb-0 ${(postTokenPoolStatsFetch.tokenPoolStats?.priceChange24h ?? 0) >= 0 ? "text-selection" : "text-error"}`}>{(postTokenPoolStatsFetch.tokenPoolStats?.priceChange24h ?? 0) >= 0 ? <CaretUp /> : <CaretDown />} {postTokenPoolStatsFetch.tokenPoolStats?.priceChange24h.toFixed(2) ?? "-"}%</h6>
                                                </>}
                                            </div>
                                        </div>
                                        <div className="d-flex ms-auto">
                                            <div className="d-flex flex-column align-items-center mx-3">
                                                <h6 className="pool-stat-header mb-1">Volume</h6>
                                                {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value mb-0">{postTokenPoolStatsFetch.tokenPoolStats?.volume24h ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.volume24h, true)}` : "-"}</h5>}
                                            </div>
                                            <div className="d-flex flex-column align-items-center mx-3">
                                                <h6 className="pool-stat-header mb-1">Liquidity</h6>
                                                {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value mb-0">{postTokenPoolStatsFetch.tokenPoolStats?.liquidity ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.liquidity, true)}` : "-"}</h5>}
                                            </div>
                                            <div className="d-flex flex-column align-items-center mx-3">
                                                <h6 className="pool-stat-header mb-1">FDV</h6>
                                                {postTokenPoolStatsFetch.fetching ? <div className="loading-square" style={{ width: "70px", height: "25px" }}></div> : <h5 className="pool-stat-value mb-0">{postTokenPoolStatsFetch.tokenPoolStats?.fdv ? `$${formatNumberForPrice(postTokenPoolStatsFetch.tokenPoolStats.fdv, true)}` : "-"}</h5>}
                                            </div>
                                        </div>
                                    </div> : ""
                                }

                                <div id="alpha-post-research" className="mt-4">{selectedPostFetch.boardPost === null ? <div className="loading-square" style={{ width: "100%", height: "150px" }}></div> : <span style={{ display: "block" }} dangerouslySetInnerHTML={{ __html: purify.sanitize(selectedPostFetch.boardPost?.content.research) }}></span>}</div>
                                {selectedPostFetch.boardPost?.content.postType === "technicalAnalysis" && <div className="alpha-post-ta-chart mt-2 mb-3">
                                    {selectedPostFetch.boardPost !== null && selectedPostFetch.boardPost.content.prediction.technicalAnalysis!.advancedOptions.taChartUrl !== null && <LazyLoadImage
                                        height={window.innerWidth > 768 ? 500 : 300}
                                        src={selectedPostFetch.boardPost.content.prediction.technicalAnalysis!.advancedOptions.taChartUrl} // use normal <img> attributes as props
                                        width={1} />}
                                </div>}

                                <div className="alpha-post-creation position-relative d-flex justify-content-between justify-content-md-end mt-3 px-2">
                                    <div className="alpha-post-interactions d-flex d-md-none align-items-center">
                                        <CaretUp className={`svg-inline--fa fa-caret-up hover-pointer fa-2x mx-2 ${currentUser !== null && selectedPostFetch.boardPost?.interactions.upvote.accounts.includes(currentUser.userId) ? "text-selection" : ""}`} onClick={() => selectedPostFetch.boardPost !== null && interactWithPost(selectedPostFetch.boardPost, PostInteraction.Upvote)} />
                                        {selectedPostFetch.boardPost === null ? <div className="loading-square" style={{ width: "50px", height: "25px" }}></div> : selectedPostFetch.boardPost.interactions.upvote.accounts.length - selectedPostFetch.boardPost.interactions.downvote.accounts.length}
                                        <CaretDown className={`svg-inline--fa fa-caret-down hover-pointer fa-2x mx-2 ${currentUser !== null && selectedPostFetch.boardPost?.interactions.downvote.accounts.includes(currentUser.userId) ? "text-error" : ""}`} onClick={() => selectedPostFetch.boardPost !== null && interactWithPost(selectedPostFetch.boardPost, PostInteraction.Downvote)} />

                                        {selectedPostFetch.boardPost !== null && <div className="d-flex ms-3">
                                            <OverlayTrigger
                                                trigger={["focus"]}
                                                placement="right"
                                                overlay={
                                                    <Popover id="share-post-popover" onClick={(e) => sharePostButtonRef.current?.focus()}>
                                                        <Popover.Body>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <AddressClicker address={`${getFullBaseUrl(window)}/big-board/${selectedPostFetch.boardPost.id}`} component={<span><FontAwesomeIcon icon={faLink} style={{ width: "20px", marginRight: ".8rem" }} /> Copy link to post</span>} />
                                                            </div>
                                                            <div className="d-flex align-items-center mt-2">
                                                                <a style={{ textDecoration: "none", color: "black" }} href={`https://twitter.com/intent/tweet?text=${selectedPostFetch.boardPost.content.title.replaceAll(" ", "%20")}%0A%0ANew%20alpha%20post%20on%20Wolves%20Capital%20Alpha%20Club&url=${getFullBaseUrl(window, true)}%2Fbig-board%2F${selectedPostFetch.boardPost.id}`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} style={{ width: "20px", marginRight: ".8rem" }} /> Share on Twitter</a>
                                                            </div>
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                            >
                                                <button className="btn-simple" ref={sharePostButtonRef} onClick={(e) => sharePostButtonRef.current?.focus()}><FontAwesomeIcon icon={faArrowUpFromBracket} /></button>
                                            </OverlayTrigger>
                                            <button className="btn-simple ms-2" onClick={() => toggleSavePost(selectedPostFetch.boardPost)}><FontAwesomeIcon icon={currentUser?.userAlphaInfo[selectedPostFetch.boardPost.content.asset.assetClass].savedPostsIds.includes(selectedPostFetch.boardPost.id) ? faBookmark : faRegularBookmark} /></button>
                                        </div>}
                                    </div>
                                    <div className="d-flex flex-column">
                                        {selectedPostFetch.boardPost === null ? <div className="loading-square" style={{ width: "200px" }}></div> : <span><img src={selectedPostFetch.boardPost.creation.user.pfpUrl!} className="pfp-icon" /> <a className="text-white" target="_blank" rel="noopener noreferrer" href={`https://twitter.com/${selectedPostFetch.boardPost.creation.user.username}`}>{selectedPostFetch.boardPost.creation.user.username}</a> · {timestampToRelative(selectedPostFetch.boardPost.creation.timestamp, true)}</span>}
                                        {/* {selectedPostFetch.boardPost === null ? <div className="loading-square" style={{ width: "200px" }}></div> : <span><img src={selectedPostFetch.boardPost.creation.user.pfpUrl!} className="pfp-icon" /> <a className="text-white" target="_blank" rel="noopener noreferrer" href={`${MAIN_WEBSITE_URL + `/brokers-globe/account/${selectedPostFetch.boardPost.creation.user.username}/alpha-club`}`}>{selectedPostFetch.boardPost.creation.user.username}</a> · {timestampToRelative(selectedPostFetch.boardPost.creation.timestamp, true)}</span>} */}
                                        {selectedPostFetch.boardPost?.creation.user.userId === currentUser?.userId && selectedPostTimeLeftToDelete !== undefined && selectedPostTimeLeftToDelete > 0 && <div className="d-flex justify-content-end align-items-center"><span className="me-2">{timestampToClockTime(selectedPostTimeLeftToDelete / 1000)}</span> <button id="delete-post-button" className="btn-simple" disabled={selectedPostTimeLeftToDelete <= 0 || selectedPostFetch.deleting} onClick={tryDeleteSelectedPost}><FontAwesomeIcon icon={faTrash} /></button></div>}
                                    </div>
                                </div>

                                <AlphaPostCommentWriter isComment={true} tradingViewChart={tradingViewChart} selectedPostFetch={selectedPostFetch} addNewComment={addCommentToBoardPostBaseComments} commentReplyTarget={commentReplyTarget} setCommentReplyTarget={setCommentReplyTarget} />

                                <div id="nfa-disclaimer-container" className="d-flex flex-column w-100 mt-4">
                                    <h6 className="text-header">DISCLAIMER</h6>
                                    <p className="text-normal">The information in this post and comments is not meant to be, and does not constitute, financial, investment, trading, or other types of advice or recommendations supplied or endorsed by Westmont Capital.</p>
                                </div>
                                <div style={{ height: "1px", background: "rgba(255, 255, 255, 0.4)", marginTop: "2rem", borderRadius: "3rem" }}></div>
                            </div>
                        </div>
                        {commentsContainer}
                    </div>}
            </Modal.Body>
        </Modal >
    )
}

export default AlphaPostViewerModal;
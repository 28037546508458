import { gsap } from "gsap";

import { BoardPostInfo, CoinInfo, NFTInfo, TokenInfo } from "./types"
import api from "./api/api";
import { CHAIN_SLUGS } from "./constants";

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export function shortenAddress(address: string, borderLengths: number = 5): string {
    return address.slice(0, borderLengths) + "..." + address.slice(-borderLengths, address.length)
}

export function timestampToSmallDate(timestamp: number) {
    var a = new Date(timestamp * 1000);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    // var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
    const time = `${month} ${date}`
    return time;
}
export function timestampToLongDate(timestamp: number) {
    var a = new Date(timestamp * 1000);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
    // const time = `${month} ${date}`
    return time;
}

export function timestampToRelative(msTimestamp: number, replaceSmallGap = false): string {
    const currentTimestamp = Date.now();

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = currentTimestamp - msTimestamp;

    if (replaceSmallGap && Math.abs(elapsed) < msPerMinute) return "now";

    if (elapsed < msPerMinute) {
        const result = Math.floor(elapsed / 1000);
        return result + ` second${result > 1 ? "s" : ""} ago`;
    }

    else if (elapsed < msPerHour) {
        const result = Math.floor(elapsed / msPerMinute);
        return result + ` minute${result > 1 ? "s" : ""} ago`;
    }

    else if (elapsed < msPerDay) {
        const result = Math.floor(elapsed / msPerHour);
        return result + ` hour${result > 1 ? "s" : ""} ago`;
    }

    else if (elapsed < msPerMonth) {
        const result = Math.floor(elapsed / msPerDay);
        return result + ` day${result > 1 ? "s" : ""} ago`;
    }

    else if (elapsed < msPerYear) {
        const result = Math.floor(elapsed / msPerMonth);
        return result + ` month${result > 1 ? "s" : ""} ago`;
    }

    else {
        const result = Math.floor(elapsed / msPerYear);
        return result + ` year${result > 1 ? "s" : ""} ago`;
    }
}

export function timestampToClockTime(timestampInSeconds: number): string {
    if (timestampInSeconds <= 0) return "00:00:00";

    const timestampInMinutes = Math.floor(timestampInSeconds / 60);
    const timestampInHours = Math.floor(timestampInSeconds / 3600);

    const seconds = Math.floor(timestampInSeconds) % 60;
    const minutes = timestampInMinutes % 60;
    const hours = timestampInHours % 24;
    return `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`
}

export async function animateLoadingSquares(containerId: string, promise: Promise<any>) {
    const fadeOutDuration = 1.5;
    const fadeInDuration = 1.5;

    const fadeOutValue = 0.3;
    const fadeInValue = 0.85;

    let loading = true;
    promise.then(() => loading = false)

    while (loading) {
        gsap.fromTo(`#${containerId} .loading-square`, { opacity: fadeInValue }, { opacity: fadeOutValue, duration: fadeOutDuration })
        await delay(fadeOutDuration * 1000)
        gsap.fromTo(`#${containerId} .loading-square`, { opacity: fadeOutValue }, { opacity: fadeInValue, duration: fadeInDuration })
        await delay(fadeInDuration * 1000)
    }
}

export function getFullBaseUrl(window: Window, encode = false) {
    const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port.length > 0 ? ":" + window.location.port : ""}`
    return encode ? encodeURIComponent(baseUrl) : baseUrl;
}

export function numberWithCommas(num: number | string) {
    if (typeof num !== "number" && typeof num !== "string") return num;
    var parts = num.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export function getRoundedPrice(price: number): string {
    if (typeof price !== "number" && typeof price !== "string") return price;

    let stringPrice = price.toString();
    if (stringPrice.includes("e-")) {
        const power = stringPrice.substring(stringPrice.indexOf("e"))
        return Number(stringPrice.substring(0, stringPrice.indexOf(power))).toFixed(2) + power;
    }
    let decimalPart = stringPrice.split('.')[1]?.substring(0, 9);
    if (decimalPart === undefined) return stringPrice;

    const nonZeroDecimalPart = Number(decimalPart).toString();
    return (price + Number.EPSILON).toFixed(price > 0.1 ? 2 : (3 + decimalPart.length - nonZeroDecimalPart.length));
    // return Math.round((price + Number.EPSILON) * 100) / 100;
}

export function formatNumberForPrice(num: number, useWithSymbol = false) {
    if (num > 1) {
        if (useWithSymbol && num >= 10000) {
            const units = ["", "K", "M", "B", "T"];
            const index = Math.floor(Math.log10(num) / 3);
            const shortenedNum = num / Math.pow(1000, index);
            return shortenedNum.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + units[index];
        } else {
            return num.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
    } else {
        const significantDigits = num.toPrecision(3);
        const parsedNumber = parseFloat(significantDigits);
        if (parsedNumber >= 0.000001) {
            const decimalPlaces = significantDigits.split('.')[1]?.length || 0;
            return parsedNumber.toLocaleString('en-US', { minimumFractionDigits: decimalPlaces });
        } else {
            // const [, exponent] = significantDigits.split("e");
            // const decimalPlaces = Math.abs(Number(exponent)) + 2;
            // return parsedNumber.toFixed(decimalPlaces);
            return parsedNumber.toString();
        }
    }
}

export function arrangePostComments(boardPostInfo: BoardPostInfo): BoardPostInfo {
    const creatorComments = [];
    const otherComments = [];
    for (const comment of boardPostInfo.interactions.comments) {
        if (comment.creation.user.userId !== boardPostInfo.creation.user.userId) otherComments.push(comment);
        else creatorComments.push(comment);
    }
    creatorComments.reverse();
    otherComments.reverse();
    boardPostInfo.interactions.comments = [...creatorComments, ...otherComments];
    return boardPostInfo;
}

export function getHtmlTextLength(html: string) {
    return new DOMParser()
        .parseFromString(html, "text/html")
        .documentElement.textContent?.length ?? 0;
}

export const DEFINED_TOKEN_ICON_URL = (token: { networkId: number, address: string }) => `https://d3kdei7cs9pk0w.cloudfront.net/${token.networkId}_${token.address}_small.png`

export function getAssetId(asset: CoinInfo | TokenInfo | NFTInfo) {
    return (asset as CoinInfo).id ?? `${(asset as TokenInfo | NFTInfo).address}:${(asset as TokenInfo | NFTInfo).networkId ?? ""}`;
}

export async function searchForTokens(searchQuery: string, limit: number) {
    if (!searchQuery.length) return [];
    const searchTokensResult = await api.defi.searchToken(searchQuery, limit);
    const searchedTokens: TokenInfo[] = []
    for (const tokenResult of searchTokensResult) {
        searchedTokens.push({
            name: tokenResult.name,
            symbol: tokenResult.symbol,
            address: tokenResult.address,
            networkId: tokenResult.networkId,
            topPoolId: tokenResult.topPairId.split(":")[0],
            iconUrl: DEFINED_TOKEN_ICON_URL(tokenResult),

            metrics: {
                liquidity: tokenResult.liquidity,
                price: tokenResult.price,
                priceChange: tokenResult.priceChange,
                volume: tokenResult.volume,
            },

            chain: { slug: CHAIN_SLUGS[tokenResult.networkId], icon: `https://icons.llamao.fi/icons/chains/rsz_${CHAIN_SLUGS[tokenResult.networkId]}.jpg` }
        })
    }
    return searchedTokens;
}

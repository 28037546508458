import "./LeaderboardWidget.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";

interface ILeaderboardRow {
    rank: number,
    name: string,
    points: number,
    pfpUrl: string
}

const mockLeaderboardData: ILeaderboardRow[] = [
    {
        rank: 1,
        name: "DonDeCastillon",
        points: 1000,
        pfpUrl: "https://pbs.twimg.com/profile_images/1628378236915777537/GGDX48s-_400x400.jpg"
    },
    {
        rank: 2,
        name: "CryptoPivot",
        points: 900,
        pfpUrl: "https://pbs.twimg.com/profile_images/1645838328090984459/NwHfx8dm_400x400.jpg"
    },
    {
        rank: 3,
        name: "JohnM1 (Telegram)",
        points: 800,
        pfpUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Telegram_2019_Logo.svg/480px-Telegram_2019_Logo.svg.png"
    },
    {
        rank: 4,
        name: "Mark",
        points: 700,
        pfpUrl: "https://pbs.twimg.com/profile_images/1634940863468978178/IHxXj7xk_400x400.jpg"
    },
    {
        rank: 5,
        name: "Klemen",
        points: 600,
        pfpUrl: "https://pbs.twimg.com/profile_images/1447587698521292801/1zMMp3SD_400x400.jpg"
    },
    {
        rank: 6,
        name: "Jane Doe",
        points: 500,
        pfpUrl: "https://www.w3schools.com/howto/img_avatar.png"
    },
    {
        rank: 7,
        name: "John Doe",
        points: 400,
        pfpUrl: "https://www.w3schools.com/howto/img_avatar.png"
    },
    {
        rank: 8,
        name: "Jane Doe",
        points: 300,
        pfpUrl: "https://www.w3schools.com/howto/img_avatar.png"
    },
    {
        rank: 9,
        name: "John Doe",
        points: 200,
        pfpUrl: "https://www.w3schools.com/howto/img_avatar.png"
    },
    {
        rank: 10,
        name: "Jane Doe",
        points: 100,
        pfpUrl: "https://www.w3schools.com/howto/img_avatar.png"
    },
];

const LeaderboardWidget: FC<any> = (props) => {

    const leaderboardRow = (row: ILeaderboardRow) => <div key={row.rank} className="leaderboard-widget-body-entry d-flex flex-row align-items-center py-2">
        <div className="leaderboard-widget-body-entry-rank d-flex justify-content-center align-items-center">
            <h6 className="mb-0">{row.rank}</h6>
        </div>
        <span className="mx-2">|</span>
        <div className="leaderboard-widget-body-entry-user d-flex justify-content-center align-items-center">
            <img src={row.pfpUrl} alt="pfp" className="me-2" />
            <h6 className="mb-0">{row.name}</h6>
        </div>
        <span className="ms-auto me-2">|</span>
        <div className="leaderboard-widget-body-entry-points d-flex justify-content-center align-items-center me-3">
            <h6 className="mb-0">{row.points} PTS</h6>
        </div>
    </div>

    return (
        <div id="leaderboard-widget" className="d-flex flex-column alpha-club-widget">
            <div id="leaderboard-widget-content" className="d-flex flex-column">
                <div id="leaderboard-widget-header" className="d-flex flex-column justify-content-between pt-2 pb-1">
                    <div id="leaderboard-widget-header-title" className="d-flex justify-content-center text-header">
                        <h3 className="header-gradient text-bold">LEADERBOARD</h3>
                    </div>
                </div>
                <div id="leaderboard-widget-body" className="d-flex flex-column">
                    <div id="leaderboard-widget-body-rows" className="d-flex flex-column">
                        {mockLeaderboardData.map((row, index) => leaderboardRow(row))}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default LeaderboardWidget;
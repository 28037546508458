import { TokenInfo, CoinInfo, NFTInfo, AssetClass } from "../../utils/types"

export interface IAlphaPostFilters {
    assets: (TokenInfo | CoinInfo | NFTInfo)[] | null;
    authors: string[] | null;
    assetClass: AssetClass;
    search: string | null;
    orderBy: { field: PostsQueryField, direction: PostsQueryOrder };
    loadingFilters: boolean;
}


export enum PostsQueryOrder {
    ASCENDING = "asc",
    DESCENDING = "desc",
}

export enum PostsQueryField {
    CreationTime = "creation.timestamp",
    TotalVotesScore = "interactions.totalVoteScore"
}
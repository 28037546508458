import "./TelegramConnectionLoader.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";
import { useHistory } from "react-router-dom";

import brokerageEmblem from "../Images/Icons/brokerageEmblem.png";
import loadingIconAnim from "../Images/Icons/loadingIcon.gif";
import { useUser } from "./Connection/useUser";

const TelegramConnectionLoader: FC<any> = (props) => {
    const { telegramDataToValidate, locationPaths } = props;

    const history = useHistory();
    const { login, loggingIn, currentUser } = useUser();

    const [isLoginFailed, setIsLoginFailed] = useState(false);

    useEffect(() => {
        initiateTelegramConnection()
    }, [telegramDataToValidate]);

    useEffect(() => {
        if (currentUser !== null) removeTelegramFromNavigation();
    }, [currentUser])

    async function initiateTelegramConnection() {
        if (telegramDataToValidate === undefined || loggingIn || currentUser !== null) return;
        setIsLoginFailed(false);
        const success = await login("telegram", telegramDataToValidate);
        setIsLoginFailed(!success)
        if (success) {
            removeTelegramFromNavigation()
        }
    }

    function removeTelegramFromNavigation() {
        history.push(`/${locationPaths.filter((locationPath: string) => locationPath !== "telegram").join("/")}${window.location.search}`);
    }

    return (
        <div id="telegram-connection-loader" className="flex-column align-items-center text-white">
            <img src={brokerageEmblem} width="125px" height="auto" />
            <h4 className="text-luxury mt-2">WCapital Alpha Club</h4>
            {loggingIn && <>
                <img src={loadingIconAnim} width="30px" height="auto" className="loading-icon mt-3" />
                <h6 className="text-normal-base-size mt-2">Connecting with Telegram...</h6>
            </>}
            {!loggingIn && isLoginFailed && <h4 className="text-normal text-danger text-center mt-4">Failed to login with Telegram.<br />Close the app and try again.</h4>}
        </div>
    )
}

export default TelegramConnectionLoader;
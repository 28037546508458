import axios from "axios";
import { TokenInfo, TokenPoolStats } from "../../types";
import { CHAIN_SLUGS, CHAIN_SLUG_TO_GECKO_ID } from "../../constants";

async function searchToken(searchQuery: string, limit: number = 1) {
    const headers = {
        "Content-Type": "application/json",
        "x-api-key": "ubGqTf4GTp8bBKKSrkKRE19OuXCFO1086KsX4Ujy"
    };
    const query = `{
        searchTokens(search:"${searchQuery}", limit: ${limit}, resolution: "1D"){
            tokens {
                name
                symbol
                address
                networkId
                topPairId
                liquidity
                price
                priceChange
                volume
            }
        }
    }`;
    const result = await axios.post("https://api.defined.fi/", { query }, { headers });
    const resultData = result.data;

    const searchedTokens = resultData.data.searchTokens.tokens;
    return searchedTokens;
}

async function getTokenPoolStats(tokenInfo: TokenInfo): Promise<TokenPoolStats | null> {
    const result = await axios.get(`https://api.geckoterminal.com/api/v2/networks/${CHAIN_SLUG_TO_GECKO_ID[CHAIN_SLUGS[tokenInfo.networkId]]}/pools/${tokenInfo.topPoolId}`);
    const resultData = result.data;
    const tokenPoolAttributes = resultData["data"]["attributes"];

    if (tokenPoolAttributes === undefined) return null;

    const tokenPoolStats: TokenPoolStats = {
        price: Number(tokenPoolAttributes["base_token_price_usd"]),
        priceChange24h: Number(tokenPoolAttributes["price_change_percentage"]["h24"]),
        volume24h: Number(tokenPoolAttributes["volume_usd"]["h24"]),
        liquidity: Number(tokenPoolAttributes["reserve_in_usd"]),
        fdv: Number(tokenPoolAttributes["fdv_usd"]),
        marketCap: Number(tokenPoolAttributes["market_cap_usd"]),
    };
    return tokenPoolStats;
}

export default {
    searchToken,
    getTokenPoolStats,
}

import "./FiltersWidget.css";
import React, { FC, useEffect, useState, useRef, useReducer, useMemo } from "react";

import Accordion from 'react-bootstrap/Accordion';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import { IAlphaPostFilters } from "../types";
import FiltersWidgetTokens from "../Components/FiltersWidgetTokens";
import { AssetClass, TokenInfo } from "../../../utils/types";
import { getAssetId } from "../../../utils/utils";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const FiltersWidget: FC<any> = (props) => {
    const { alphaPostFilters, setAlphaPostFilters, setIsWritingPost }: {
        alphaPostFilters: IAlphaPostFilters,
        setAlphaPostFilters: React.Dispatch<React.SetStateAction<IAlphaPostFilters>>,
        setIsWritingPost: React.Dispatch<React.SetStateAction<boolean>>
    } = props;

    function addAssetToFilters(asset: TokenInfo) {
        console.log(alphaPostFilters.assets)
        setAlphaPostFilters({ ...alphaPostFilters, assets: [...alphaPostFilters.assets?.filter(addedAsset => getAssetId(addedAsset) !== getAssetId(asset)).slice(0, 9) ?? [], asset] })
    }

    function removeAssetFromFilters(asset: TokenInfo) {
        setAlphaPostFilters({ ...alphaPostFilters, assets: alphaPostFilters.assets?.filter(addedAsset => getAssetId(addedAsset) !== getAssetId(asset)) ?? null })
    }

    return (
        <div id="big-board-filters-widget" className="d-flex flex-column alpha-club-widget">
            <div id="post-and-search-bar" className="d-flex">
                <button id="alpha-club-post-btn" onClick={() => setIsWritingPost(true)} className="btn btn-default d-flex align-items-center"><FontAwesomeIcon icon={faPenToSquare} className="me-1" /> Post</button>
                <div id="alpha-club-post-search-bar" className="d-flex align-items-center w-100 ms-2">
                    <button id="search-button" className="btn btn-simple">
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </button>
                    <input type="text" placeholder="Search"></input>
                </div>
            </div>
            <div id="alpha-posts-type-container" className="d-flex w-100 mt-3">
                <button onClick={(e) => setAlphaPostFilters({ ...alphaPostFilters, assetClass: AssetClass.Crypto })} className={`alpha-posts-type-crypto-btn btn btn-default w-50 me-1 ${alphaPostFilters.assetClass === AssetClass.Crypto ? "selected" : ""}`}>Crypto</button>
                <button disabled={true} onClick={(e) => setAlphaPostFilters({ ...alphaPostFilters, assetClass: AssetClass.NFT })} className={`alpha-posts-type-crypto-btn btn btn-default w-50 ms-1 ${alphaPostFilters.assetClass === AssetClass.NFT ? "selected" : ""}`}>NFTs</button>
            </div>
            <div id="alpha-posts-filters-container" className="mt-3">
                <div id="alpha-posts-filters-header" className="d-flex align-items-center justify-content-center text-header">
                    <h3 className="text-center header-gradient text-bold py-2 mb-0">FILTERS</h3>
                    {/* <button id="alpha-posts-filters-clear-btn" className="btn btn-simple ms-auto">Clear</button> */}
                </div>
                <div id="alpha-posts-filters-body">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header id="tokens-filter-header" className="d-flex align-items-center flex-wrap">
                                <span className="mb-0 me-3">Tokens</span>
                            </Accordion.Header>
                            <Accordion.Body id="tokens-filter-body" className="p-0 pt-2">
                                <FiltersWidgetTokens assetClass={alphaPostFilters.assetClass} selectedAssets={alphaPostFilters.assets} addAssetToFilters={(token: any) => addAssetToFilters(token)} removeAssetFromFilters={removeAssetFromFilters} />
                                {/* <TokenSelectorDropdown tokenType={alphaPostFilters.tokenType} selectedToken={null} setSelectedToken={(token: any) => token && addTokenToFilters(token.symbol)} /> */}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" className="position-relative">
                            <div style={{ position: "absolute", inset: "0", zIndex: 1, backgroundColor: "rgba(0,0,0,0.5)" }}></div>
                            <Accordion.Header>Author</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        </div>
    )
};

export default FiltersWidget;
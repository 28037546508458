import axios from "axios";

import { API_URL } from "../api";
import { UserAccount } from "../../types";

async function connectWithTwitter(
    oauthAccessToken: string,
    oauthTokenSecret: string,
): Promise<{ userAccount: UserAccount, success: boolean, token: string } | null> {
    const data = JSON.stringify({ oauthAccessToken, oauthTokenSecret });
    const customConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    let response;
    try {
        response = await axios.post(`${API_URL}/auth/twitter/connect-with-twitter`, data, customConfig);
    } catch (error) {
        console.error(error);
        return null
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        return null;
    }

    axios.defaults.headers.common['authorization'] = "Bearer " + responseData.token;

    return responseData;
}

async function getTwitterInfoFromOauthToken(oauthToken: string, oauthVerifier: string): Promise<any> {
    let response;
    try {
        response = await axios.get(`${API_URL}/auth/twitter/request_access_token?oauthToken=${oauthToken}&oauthVerifier=${oauthVerifier}`, { withCredentials: true });
    } catch (error) {
        console.error(error);
        return null;
    }
    const responseData = response.data;

    if (!responseData.success) {
        console.error(response);
        return null;
    }
    return responseData;
}

export default {
    connectWithTwitter,
    getTwitterInfoFromOauthToken
}
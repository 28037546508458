import "./AlphaPostPreview.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTrendUp, faArrowTrendDown, faClock, faLink, faArrowUpFromBracket, faBookmark } from "@fortawesome/free-solid-svg-icons";
import { faBookmark as faRegularBookmark } from '@fortawesome/free-regular-svg-icons'
import { faTwitter } from "@fortawesome/free-brands-svg-icons"

import { numberWithCommas, getRoundedPrice, timestampToSmallDate, timestampToRelative } from "../../../utils/utils";

import EthIcon from "../../../Images/Icons/ethereumIcon.png";
import { ReactComponent as CaretUp } from "../../../Images/Icons/CaretUp.svg";
import { ReactComponent as CaretDown } from "../../../Images/Icons/CaretDown.svg";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import AddressClicker from "../../../Components/AddressClicker";

import { useUser } from "../../../Components/Connection/useUser"
import { AssetClass, BoardPostInfo, NFTInfo, PostInteraction } from "../../../utils/types";
import { getFullBaseUrl } from "../../../utils/utils"


import createDOMPurify from "dompurify";
const purify = createDOMPurify(window);

const AlphaPostPreview: FC<any> = (props) => {
    const { post, interactWithPost, onPostClick, toggleSavePost }: { post: BoardPostInfo, interactWithPost: any, onPostClick: Function, toggleSavePost: Function } = props;

    const { currentUser } = useUser();
    const sharePostButtonRef = useRef<HTMLButtonElement>(null);

    const boardPostPredictionTimeRange = post.content.postType === "alpha" ? post.content.prediction.alpha!.timeframe : post.content.prediction.technicalAnalysis!.advancedOptions.timeframe;

    const currencySpan = <span className="currency-icon">{post.content.asset.assetClass === AssetClass.Crypto ? "$" : <img src={(post.content.asset.info as NFTInfo).pricingIcon} />}</span>

    return <div className="alpha-post-preview d-flex my-2">
        <div className="alpha-post-preview-shade"></div>
        <div className="interactions-container d-flex flex-column align-items-center px-3 pt-3">
            <CaretUp className={`svg-inline--fa fa-caret-up hover-pointer fa-2x ${currentUser && post.interactions.upvote.accounts.includes(currentUser.userId) ? "text-selection shadow-selection" : ""}`} onClick={() => interactWithPost(post, PostInteraction.Upvote)} />
            {post.interactions.upvote.accounts.length - post.interactions.downvote.accounts.length}
            {/* <FontAwesomeIcon icon={faCaretDown} size="2x" className={brokerInteractionDetails.downvoted ? "text-error" : ""} onClick={() => interactWithPost(boardInfo, PostInteraction.Downvote)} /> */}
            <CaretDown className={`svg-inline--fa fa-caret-down hover-pointer fa-2x ${currentUser && post.interactions.downvote.accounts.includes(currentUser.userId) ? "text-error shadow-error" : ""}`} onClick={() => interactWithPost(post, PostInteraction.Downvote)} />
            <div className="d-flex flex-column share-icon mt-3">
                <OverlayTrigger
                    trigger={["focus"]}
                    placement="right"
                    overlay={
                        <Popover id="share-post-popover" onClick={(e) => sharePostButtonRef.current?.focus()}>
                            <Popover.Body>
                                <div className="d-flex align-items-center mb-2">
                                    <AddressClicker address={`${getFullBaseUrl(window)}/big-board/${post.id}`} component={<span><FontAwesomeIcon icon={faLink} style={{ width: "20px", marginRight: ".8rem" }} /> Copy link to post</span>} />
                                </div>
                                <div className="d-flex align-items-center mt-2">
                                    <a style={{ textDecoration: "none", color: "black" }} href={`https://twitter.com/intent/tweet?text=${post.content.title.replaceAll(" ", "%20")}%0A%0ANew%20alpha%20post%20on%20Wolves%20Capital%20Alpha%20Club&url=${getFullBaseUrl(window, true)}%2Fbig-board%2F${post.id}`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} style={{ width: "20px", marginRight: ".8rem" }} /> Share on Twitter</a>
                                </div>
                            </Popover.Body>
                        </Popover>
                    }
                >
                    <button className="btn-simple" ref={sharePostButtonRef} onClick={(e) => sharePostButtonRef.current?.focus()}><FontAwesomeIcon icon={faArrowUpFromBracket} /></button>
                </OverlayTrigger>
                <button className="btn-simple mt-2" onClick={() => toggleSavePost(post)}><FontAwesomeIcon icon={currentUser?.userAlphaInfo[post.content.asset.assetClass].savedPostsIds.includes(post.id) ? faBookmark : faRegularBookmark} /></button>
            </div>
        </div>
        <div className="information-container d-flex flex-column w-100 px-3 py-3" onClick={(e) => onPostClick(post)}>
            <div className="post-trade-details d-flex">
                <div className="d-flex align-items-center token-info ms-2 me-3 my-1 order-first">
                    <img src={post.content.asset.info.iconUrl} className="token-icon me-2" /> {post.content.asset.info.symbol} <span className="price-at-creation ms-2">({numberWithCommas(getRoundedPrice(post.content.asset.priceAtCreation))} {currencySpan})</span>
                </div>
                <div className={`d-flex align-items-center price-range-info ${post.content.position === "long" ? "uptrend" : "downtrend"} mx-2 my-1 order-last`}>
                    {/* <FontAwesomeIcon icon={boardInfo.content.position === "long"  ? faArrowTrendUp : faArrowTrendDown} className="me-2" /> {numberWithCommas(getRoundedPrice(boardInfo.content.targetRange.start))}$ - {numberWithCommas(getRoundedPrice(boardInfo.content.targetRange.end))}$ */}
                    {post.content.postType === "technicalAnalysis" ? <span className="text-white">{numberWithCommas(getRoundedPrice(post.content.prediction.technicalAnalysis!.entryTarget))} {currencySpan}</span> : ""} <FontAwesomeIcon icon={post.content.position === "long" ? faArrowTrendUp : faArrowTrendDown} className="mx-2" /> {post.content.postType === "technicalAnalysis" ? <>{numberWithCommas(getRoundedPrice(post.content.prediction.technicalAnalysis!.exitTargets[0]))} {currencySpan}</> : ""}
                </div>
                {boardPostPredictionTimeRange !== null ? <div className="d-flex align-items-center time-range-info mx-2 my-1 order-md-last">
                    <FontAwesomeIcon icon={faClock} className="me-2" /> {timestampToSmallDate(boardPostPredictionTimeRange.start / 1000)} - {timestampToSmallDate(boardPostPredictionTimeRange.end / 1000)}
                </div> : null}
            </div>
            <div className="post-title d-flex align-items-center text-header mt-2 mb-3">
                <h4 className="header-gradient text-bold">{post.content.title}</h4>
            </div>
            <div className="alpha-post-research-preview"><div dangerouslySetInnerHTML={{ __html: purify.sanitize(post.content.research) }}></div></div>
            {post.content.prediction.technicalAnalysis?.advancedOptions.taChartUrl ? <div className="alpha-post-research-chart mt-3 mb-2"><img src={post.content.prediction.technicalAnalysis?.advancedOptions.taChartUrl} /></div> : ""}
            <div className="alpha-post-creation d-flex align-items-center ms-auto me-2">
                <img src={post.creation.user.pfpUrl ?? undefined} className="pfp-icon me-1" /> {post.creation.user.username} · {timestampToRelative(post.creation.timestamp)}
            </div>
            <div className="alpha-post-bottom-fade"></div>
        </div>
    </div>
};

export default AlphaPostPreview;
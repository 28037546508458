import "./AlphaPostComment.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChessKnight, faCaretDown, faCaretUp, faMessage, faTrash } from '@fortawesome/free-solid-svg-icons'

import { toast } from "react-toastify";

import AlphaPostCommentWriter from "./AlphaPostCommentWriter"

import { MAIN_WEBSITE_URL } from "../../../utils/constants"
import { BoardPostCommentInfo } from "../../../utils/types"
import { delay, timestampToRelative } from "../../../utils/utils"
import api from "../../../utils/api/api"

import { useUser } from "../../../Components/Connection/useUser";

import createDOMPurify from "dompurify";
const purify = createDOMPurify(window);

interface CommentRepliesFetch {
    fetching: boolean;
    repliesOpened: boolean;
    replies: BoardPostCommentInfo[] | null;
    fetch: Function
}

const AlphaPostComment: FC<any> = (props) => {
    const { className, tradingViewChart, selectedPostFetch, boardPostCommentInfo, deleteComment, commentReplyTarget, setCommentReplyTarget }: { className: string, tradingViewChart: any, selectedPostFetch: any, boardPostCommentInfo: BoardPostCommentInfo, deleteComment: Function, commentReplyTarget: { originCommentId: string, id: string } | null, setCommentReplyTarget: any } = props;

    const { currentUser } = useUser();

    const [commentRepliesFetch, setCommentRepliesFetch] = useState<CommentRepliesFetch>({ fetching: false, repliesOpened: false, replies: [], fetch: fetchCommentReplies })

    function handleViewRepliesClick() {
        if (commentRepliesFetch.repliesOpened) {
            setCommentRepliesFetch({ ...commentRepliesFetch, repliesOpened: false });
            return
        }
        fetchCommentReplies()
    }

    async function fetchCommentReplies() {
        if (commentRepliesFetch.fetching || (commentRepliesFetch.replies !== null && commentRepliesFetch.replies.length > 0)) {
            setCommentRepliesFetch({ ...commentRepliesFetch, repliesOpened: true });
            return false
        };
        setCommentRepliesFetch({ ...commentRepliesFetch, fetching: true, repliesOpened: true });
        const result = await api.boardPost.getCommentInfo(selectedPostFetch.boardPost.id, boardPostCommentInfo.id)
        if (!result.success) {
            toast.error(result.error)
            return false
        }
        const replies = result.boardPostComment.replies;
        setCommentRepliesFetch({ ...commentRepliesFetch, fetching: false, repliesOpened: true, replies })
        return true
    }

    async function addReplyToCommentReplies(newCommentInfo: BoardPostCommentInfo) {
        if (!(await fetchCommentReplies())) {
            const newReplies = commentRepliesFetch.replies === null ? [newCommentInfo] : [...commentRepliesFetch.replies, newCommentInfo]
            setCommentRepliesFetch({ ...commentRepliesFetch, replies: newReplies, repliesOpened: true })
        }
        setCommentReplyTarget(undefined)
    }

    async function deleteReply(replyId: string) {
        if (currentUser === null || selectedPostFetch.boardPost === null) return;
        const commentId = boardPostCommentInfo.id;

        const response = await api.boardPost.deleteComment(selectedPostFetch.boardPost.id, commentId, replyId);
        if (!response.success) {
            toast.error(response.error);
        }
        removeReplyFromCommentReplies(replyId);
    }

    function removeReplyFromCommentReplies(replyId: string) {
        if (commentRepliesFetch.replies === null) return;
        const newReplies = commentRepliesFetch.replies.map((reply: BoardPostCommentInfo) => {
            if (reply.id === replyId) reply.content = null;
            return reply;
        });
        setCommentRepliesFetch({ ...commentRepliesFetch, replies: newReplies })
    }

    function getReplyInfoOfChildren(parentId: string, deepness: number) {
        if (commentRepliesFetch.replies === null) return null;
        const children = commentRepliesFetch.replies.filter(reply => reply.replyInfo?.replyParentId === parentId)
        const childrenReplyContainers = children.reverse().map(reply => {

            const replyChildren = getReplyInfoOfChildren(reply.id, deepness + 1)

            return <div key={reply.id} className="comment-reply-container d-flex flex-column mt-2 mt-md-3 mt-lg-4" style={{ marginLeft: "2rem" }}>
                {replyChildren !== null && replyChildren.length > 0 && <div className="comment-replies-line"></div>}

                <div className="comment-reply-info d-flex flex-column">
                    <div className="d-flex align-items-center commenter-user-info text-small">
                        <img src={reply.creation.user.pfpUrl!} className="pfp-icon" />
                        <p className="ms-2 mb-0"><a className="text-white" target="_blank" rel="noopener noreferrer" href={`${MAIN_WEBSITE_URL + `/brokers-globe/account/${reply.creation.user.username}/alpha-club`}`}>{reply.creation.user.username}</a> {reply.creation.user.isEliteCreator && <FontAwesomeIcon icon={faChessKnight} className="elite-member-icon mx-1" title="Elite Club Member" />} <span> · {timestampToRelative(reply.creation.timestamp, true)}</span></p>
                    </div>
                    {reply.content === null ? <span style={{ color: "#888888" }} className="mx-5 my-2">Comment has been deleted</span> : <><div className="mx-5 mt-2" dangerouslySetInnerHTML={{ __html: purify.sanitize(reply.content.research) }}></div>
                        {reply !== null && reply.content.taChartUrl !== null && <div className="alpha-post-comment-ta-chart d-flex justify-content-center mt-2 mb-3">
                            <img src={reply.content.taChartUrl} />
                        </div>}
                    </>}
                    <div className="d-flex comment-interactions mt-1 ms-3 ps-3">
                        {currentUser !== null && <button onClick={() => commentReplyTarget?.id !== reply.id ? setCommentReplyTarget({ id: reply.id, originCommentId: boardPostCommentInfo.id }) : setCommentReplyTarget(undefined)} className="btn-simple comment-interactions-btn d-flex align-items-center text-small ms-3"><FontAwesomeIcon icon={faMessage} /> <span className="ms-1">Reply</span></button>}
                        {currentUser !== null && reply.creation.user.userId === currentUser.userId && reply.content !== null ? <button className="btn-simple comment-interactions-btn d-flex align-items-center text-small ms-3" onClick={() => deleteReply(reply.id)}><FontAwesomeIcon icon={faTrash} /> <span className="ms-1">Delete</span></button> : null}
                    </div>
                    {commentReplyTarget?.id === reply.id && <AlphaPostCommentWriter isComment={false} className="ms-4" tradingViewChart={tradingViewChart} selectedPostFetch={selectedPostFetch} addNewComment={addReplyToCommentReplies} commentReplyTarget={commentReplyTarget} setCommentReplyTarget={setCommentReplyTarget} />}
                </div>
                {replyChildren}
            </div>
        })

        return childrenReplyContainers;
    }

    const commentRepliesContainer = React.useMemo(() => <div className="comment-replies-container">
        {/* <div className="comment-replies-line"></div> */}
        {getReplyInfoOfChildren(boardPostCommentInfo.id, 1)}
    </div>, [boardPostCommentInfo.id, commentRepliesFetch.replies, commentReplyTarget])

    return (
        <div className={`alpha-post-comment d-flex flex-column ${className}`}>
            {commentRepliesFetch.repliesOpened && commentRepliesFetch.replies !== null && commentRepliesFetch.replies.length > 0 && <div className="comment-replies-line"></div>}
            <div className="alpha-post-comment-info d-flex flex-column">
                {/* {commentRepliesFetch.repliesOpened && <div className="comment-replies-line"></div>} */}
                <div className="d-flex align-items-center commenter-user-info text-small">
                    <img src={boardPostCommentInfo.creation.user.pfpUrl!} className="pfp-icon" />
                    <p className="ms-2 mb-0"><a className="text-white" target="_blank" rel="noopener noreferrer" href={`${MAIN_WEBSITE_URL + `/brokers-globe/account/${boardPostCommentInfo.creation.user.username}/alpha-club`}`}>{boardPostCommentInfo.creation.user.username}</a> {boardPostCommentInfo.creation.user.isEliteCreator && <FontAwesomeIcon icon={faChessKnight} className="elite-member-icon mx-1" title="Elite Club Member" />} <span> · {timestampToRelative(boardPostCommentInfo.creation.timestamp, true)}</span></p>
                </div>
                {boardPostCommentInfo.content === null ? <span style={{ color: "#888888" }} className="mx-5 my-2">Comment has been deleted</span> : <><div className="mx-5 mt-2" dangerouslySetInnerHTML={{ __html: purify.sanitize(boardPostCommentInfo.content.research) }}></div>
                    {boardPostCommentInfo !== null && boardPostCommentInfo.content.taChartUrl !== null && <div className="alpha-post-comment-ta-chart d-flex justify-content-center mt-2 mb-3">
                        <img src={boardPostCommentInfo.content.taChartUrl} />
                    </div>}
                </>}
                <div className="d-flex comment-interactions ms-5 ps-3">
                    <button onClick={handleViewRepliesClick} className="btn-simple comment-interactions-btn d-flex align-items-center text-small"><FontAwesomeIcon icon={commentRepliesFetch.repliesOpened ? faCaretUp : faCaretDown} /> <span className="ms-1">View Replies</span></button>
                    {currentUser !== null && <button onClick={() => commentReplyTarget?.id !== boardPostCommentInfo.id ? setCommentReplyTarget({ originCommentId: boardPostCommentInfo.id, id: boardPostCommentInfo.id }) : setCommentReplyTarget(undefined)} className="btn-simple comment-interactions-btn d-flex align-items-center text-small ms-3"><FontAwesomeIcon icon={faMessage} /> <span className="ms-1">Reply</span></button>}
                    {currentUser !== null && boardPostCommentInfo.creation.user.userId === currentUser.userId && boardPostCommentInfo.content !== null ? <button className="btn-simple comment-interactions-btn d-flex align-items-center text-small ms-3" onClick={() => deleteComment(boardPostCommentInfo.id)}><FontAwesomeIcon icon={faTrash} /> <span className="ms-1">Delete</span></button> : null}
                </div>
                {commentReplyTarget?.id === boardPostCommentInfo.id && <AlphaPostCommentWriter isComment={false} className="ms-4" tradingViewChart={tradingViewChart} selectedPostFetch={selectedPostFetch} addNewComment={addReplyToCommentReplies} commentReplyTarget={commentReplyTarget} setCommentReplyTarget={setCommentReplyTarget} />}
            </div>
            {
                commentRepliesFetch.repliesOpened && commentRepliesContainer
            }
        </div>
    )
}

export default AlphaPostComment;
import './App.css';
import './BootstrapOverride.css';
import './ToastifyOverride.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

import MainHandler from './MainHandler'

// import { WagmiConfig, createClient, configureChains, mainnet } from 'wagmi'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'


import { getDefaultProvider } from 'ethers'
import { UserProvider } from './Components/Connection/UserProvider';

// const { chains, provider, webSocketProvider } = configureChains(
//     [mainnet],
//     [alchemyProvider({ apiKey: 'yourAlchemyApiKey' }), publicProvider()],
// )


// const client = createClient({
//   autoConnect: true,
//   provider: getDefaultProvider(),
// })
// const client = createClient({
//     autoConnect: true,
//     connectors: [
//         new MetaMaskConnector({ chains }),
//         new CoinbaseWalletConnector({
//             chains,
//             options: {
//                 appName: 'wagmi',
//             },
//         }),
//         new WalletConnectConnector({
//             chains,
//             options: {
//                 qrcode: true,
//             },
//         }),
//         new InjectedConnector({
//             chains,
//             options: {
//                 name: 'Injected',
//                 shimDisconnect: true,
//             },
//         }),
//     ],
//     provider,
//     webSocketProvider,
// })

gsap.registerPlugin(ScrollTrigger)

function App() {

    return (
        // <WagmiConfig client={client}>
        <UserProvider>
            <div className="App">
                <MainHandler />
            </div>
            <div className="text-white mb-2 ms-2" style={{ position: "fixed", bottom: "0", left: "0" }}>
                v0.3
            </div>
        </UserProvider>
        // </WagmiConfig>
    );
}

export default App;

import "./NewsFeedWidget.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";

import { animateLoadingSquares, timestampToRelative } from "../../../utils/utils"
import api from "../../../utils/api/api";

interface IAlphaClubNews {
    author: {
        name: string
    },
    content: {
        title: string,
        description: string,
        image: string | null
    },
    link: string;
    timestamp: number;
    id: string;
}

// const news: IAlphaClubNews[] = [
//     {
//         author: { name: "cryptounfolded" },
//         content: {
//             description: "The number of addresses holding 1+ wholecoin Bitcoin just reached an ATH of 1 million.",
//             image: "https://pbs.twimg.com/media/FwPr_mcaUAEdnkA?format=jpg&name=large"
//         },
//         link: ""
//     },
//     {
//         author: { name: "WatcherGuru" },
//         content: {
//             description: "JUST IN: Tether to allocate up to 15% of its profits towards purchasing #Bitcoin.",
//             image: "https://watcher.guru/news/wp-content/uploads/2023/05/stablecoin-tether-usdt-800x460.jpg.webp"
//         },
//         link: ""
//     },
//     {
//         author: { name: "cryptounfolded" },
//         content: {
//             description: "Binance pulling out of Canada, says doing business there untenable",
//             image: null
//         },
//         link: ""
//     },
//     {
//         author: { name: "cryptounfolded" },
//         content: {
//             description: "Currently, 4.3M Long-Term Holder coins are held at a loss, equivalent to 22.2% of the circulating supply",
//             image: "https://pbs.twimg.com/media/Fv9CzgAX0AM6v_A?format=jpg&name=medium"
//         },
//         link: ""
//     },
// ]

interface INewsFeedFetch {
    recent: {
        feed: IAlphaClubNews[];
        fetching: boolean;
        currentError: string | undefined;
        fullyLoaded: boolean;
    };
    top: {
        feed: IAlphaClubNews[];
        fetching: boolean;
        currentError: string | undefined;
        fullyLoaded: boolean;
    };
}
const MARKET_NEWS_QUERY_LENGTH = 15;
const NewsFeedWidget: FC<any> = (props) => {

    const [newsFeedOrder, setNewsFeedOrder] = useState<"recent" | "top">("recent");
    const [newsFeedFetch, setNewsFeedFetch] = useState<INewsFeedFetch>({ recent: { feed: [], fetching: false, currentError: undefined, fullyLoaded: false }, top: { feed: [], fetching: false, currentError: undefined, fullyLoaded: false } });

    useEffect(() => {
        if (newsFeedFetch[newsFeedOrder].feed.length === 0) fetchNewsFeed();
    }, [newsFeedOrder]);

    async function fetchNewsFeed() {
        if (newsFeedFetch[newsFeedOrder].fetching) return;
        setNewsFeedFetch({ ...newsFeedFetch, [newsFeedOrder]: { ...newsFeedFetch[newsFeedOrder], fetching: true } });
        const fetchingMarketNewsPromise = api.marketNews.getMarketNews(MARKET_NEWS_QUERY_LENGTH);
        animateLoadingSquares("news-feed-widget-body-posts", fetchingMarketNewsPromise)
        const result = await fetchingMarketNewsPromise;
        const updatedNewsFeedFetch = { ...newsFeedFetch, fetching: false };
        if (result.success) {
            updatedNewsFeedFetch[newsFeedOrder].feed = [...updatedNewsFeedFetch[newsFeedOrder].feed, ...result.marketNews];
            if (result.marketNews.length < MARKET_NEWS_QUERY_LENGTH) updatedNewsFeedFetch[newsFeedOrder].fullyLoaded = true;
        } else {
            updatedNewsFeedFetch[newsFeedOrder].currentError = result.error ?? "An error occurred. Try again.";
        }
        setNewsFeedFetch(updatedNewsFeedFetch);
    }

    const newsContainer = (news: IAlphaClubNews | undefined) => <div className="news-feed-widget-body-post d-flex flex-column py-2 px-2">
        <div className="news-feed-widget-body-post-author">
            {news ? <h6>{news.author.name}</h6> : <div className="loading-square ms-0" style={{ width: "120px", height: "25px" }}></div>}
        </div>
        {news ? <a href={news.link} target="_blank" rel="noopener noreferrer" className="news-feed-widget-body-post-content d-flex flex-column">
            <h6 className="mb-1">{news.content.title}</h6>
            {news.content.image !== null ? <img src={news.content.image} alt="Post Image" /> : ""}
            <p className="mt-1 mb-0">{news.content.description}</p>
            <span className="text-end">{timestampToRelative(news.timestamp)}</span>
        </a> : <div className="d-flex flex-column mt-3">
            <div className="loading-square w-100 mb-1" style={{}}></div>
            <div className="loading-square w-100" style={{ height: "180px" }}></div>
            <div className="d-flex flex-column">
                <div className="loading-square mt-2 ms-0" style={{ width: "90%" }}></div>
                <div className="loading-square w-100 mt-1" style={{}}></div>
                <div className="loading-square mt-1 ms-0" style={{ width: "85%" }}></div>
            </div>
            <div className="loading-square mt-2 me-0" style={{ width: "100px", height: "25px" }}></div>
        </div>}
    </div>

    return (
        <div id="news-feed-widget" className="d-flex flex-column alpha-club-widget">
            <div id="news-feed-widget-content" className="d-flex flex-column">
                <div id="news-feed-widget-header" className="d-flex flex-column justify-content-between">
                    <div id="news-feed-widget-header-title" className="d-flex justify-content-center text-header pt-2">
                        <h3 className="header-gradient text-bold">NEWS FEED</h3>
                    </div>
                    <div id="news-feed-widget-header-buttons" className="d-flex pt-1 pb-2 px-1">
                        <button onClick={() => setNewsFeedOrder("recent")} className={`btn btn-default mx-1 px-4 ${newsFeedOrder === "recent" ? "selected" : ""}`}>Recent</button>
                        <button disabled={true} onClick={() => setNewsFeedOrder("top")} className={`btn btn-default mx-1 px-4 ${newsFeedOrder === "top" ? "selected" : ""}`}>Top</button>
                    </div>
                </div>
                <div id="news-feed-widget-body" className="d-flex flex-column">
                    <div id="news-feed-widget-body-posts" className="d-flex flex-column">
                        {newsFeedFetch[newsFeedOrder].feed.map((news: IAlphaClubNews) => newsContainer(news))}
                        {newsFeedFetch[newsFeedOrder].fetching ? Array(3).fill(0).map(() => newsContainer(undefined)) : ""}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default NewsFeedWidget;
import axios from "axios";

import { BoardPostCommentInfo, BoardPostInfo, PostsQueryOrder, PostsQueryField, AssetClass } from "../../types";

import { API_URL } from "../api"

async function createPost(
    boardPostContent: BoardPostInfo["content"],
    boardPostIsToBeAnalyzed: boolean,
): Promise<{ success: boolean, boardPostInfo: BoardPostInfo | null, error: string | null }> {
    const data = JSON.stringify({ boardPostContent, boardPostIsToBeAnalyzed });
    const customConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    let response;
    try {
        response = await axios.post(`${API_URL}/alpha-club/big-board/posts/create`, data, customConfig);
    } catch (error) {
        console.error(error);
        return { success: false, boardPostInfo: null, error: "An error occured. Try again." };
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        return { success: false, boardPostInfo: null, error: responseData.error };
    }

    return { success: true, boardPostInfo: responseData.boardPostInfo, error: null };
}

async function getPostsInfo(
    boardPostsIds: string[]
): Promise<{ success: boolean, boardPosts: BoardPostInfo[] | null }> {
    let response;
    try {
        response = await axios.get(`${API_URL}/alpha-club/big-board/posts?${boardPostsIds.map((boardPostId) => `boardPostId=${boardPostId}&`)}`);
    } catch (error) {
        console.error(error);
        return { success: false, boardPosts: null };
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        return { success: false, boardPosts: null };
    }

    return responseData;
}


async function interactWithPost(
    interactionType: string,
    boardPostId: string,
    isRemoveInteraction: boolean
): Promise<boolean> {
    let response;
    try {
        if (isRemoveInteraction) response = await axios.delete(`${API_URL}/alpha-club/big-board/posts/interact/${interactionType}?boardPostId=${boardPostId}`);
        else response = await axios.get(`${API_URL}/alpha-club/big-board/posts/interact/${interactionType}?boardPostId=${boardPostId}`);
    } catch (error) {
        // console.error(error);
        return false;
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
    }

    return responseData.success;
}

async function getRecentPosts(
    queryOrder: PostsQueryOrder | undefined,
    queryOrderField: PostsQueryField | undefined,
    // timeRangeStartAfter: number | null,
    // timeRangeStartBefore: number | null,
    queryLength: number,

    createdBefore: number | undefined,
    createdAfter: number | undefined,

    filterAssetIds: string[] | undefined,
    filterAssetClass: AssetClass,
    filterAuthorIds: string[] | undefined,
    // filterEliteOnly: boolean,
): Promise<{ success: boolean, boardPosts: BoardPostInfo[] }> {
    console.log(filterAssetIds)
    const params: any = {
        queryOrder,
        queryOrderField,
        queryLength,

        createdBefore,
        createdAfter,

        filterAssetIds,
        filterAssetClass: filterAssetClass.valueOf(),
        filterAuthorIds
    }
    // if (filterEliteOnly) params.filterEliteOnly = true;
    let response;
    try {
        response = await axios.get(`${API_URL}/alpha-club/big-board/posts/recent`, { params });
    } catch (error) {
        console.error(error);
        return { success: false, boardPosts: [] };
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        return { success: false, boardPosts: [] };
    }

    return { success: true, boardPosts: responseData.boardPosts };
}

// async function test() {
//     const allRequests = new Array(200).fill(0).map((_) => {
//         const request = axios.get("https://api.opensea.io/api/v1/collection/boredapeyachtclub/stats");
//         return request
//     })

//     const allResponses = await Promise.all(allRequests);
//     console.log(allResponses.map(resp => resp.data))
// }

async function deletePost(
    boardPostId: string,
): Promise<boolean> {
    const customConfig: any = {
        headers: {
            'Content-Type': 'application/json'
        },
        params: {
            boardPostId,
        }
    };

    let response;
    try {
        response = await axios.delete(`${API_URL}/alpha-club/big-board/posts/delete`, customConfig);
    } catch (error) {
        console.error(error);
        return false;
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
    }

    return responseData.success;
}


async function createComment(
    originBoardPostId: string,
    originCommentId: string | undefined,
    replyParentId: string | undefined,
    commentContent: BoardPostCommentInfo["content"],
) {
    const data = JSON.stringify({ originBoardPostId, originCommentId, replyParentId, commentContent });
    const customConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    let response;
    try {
        response = await axios.post(`${API_URL}/alpha-club/big-board/posts/comments/create`, data, customConfig);
    } catch (error) {
        console.error(error);
        return { success: false, error: "An error occured. Try again." };
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        return { success: false, error: responseData.error };
    }

    return { success: true, boardPostComment: responseData.boardPostComment };
}

async function getCommentInfo(
    originBoardPostId: string,
    commentId: string
) {
    const params = {
        originBoardPostId,
        commentId,
    }
    let response;
    try {
        response = await axios.get(`${API_URL}/alpha-club/big-board/posts/comments`, { params });
    } catch (error) {
        console.error(error);
        return { success: false, error };
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
    }

    return responseData;
}

async function deleteComment(
    originBoardPostId: string,
    commentId: string,
    replyId: string | undefined = undefined
) {
    let response;
    try {
        const params = {
            originBoardPostId,
            commentId,
            replyId,
        }
        response = await axios.delete(`${API_URL}/alpha-club/big-board/posts/comments`, { params });
    } catch (error) {
        console.error(error);
        return { success: false, error: "An error occured. Try again." };
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        return { success: false, error: responseData.error };
    }

    return { success: true };
}


async function savePost(
    postId: string,
    isCrypto: boolean
): Promise<{ success: boolean, error: string | undefined }> {
    const data = JSON.stringify({ postId, isCrypto });
    const customConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    let response;
    try {
        response = await axios.post(`${API_URL}/alpha-club/big-board/posts/save`, data, customConfig);
    } catch (error) {
        console.error(error);
        return { success: false, error: "An error occured. Try again." };
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
    }

    return responseData;
}

async function removeSavedPost(
    postId: string,
    isCrypto: boolean
): Promise<{ success: boolean, error: string | undefined }> {
    const params = {
        postId,
        isCrypto
    };

    let response;
    try {
        response = await axios.delete(`${API_URL}/alpha-club/big-board/posts/save`, { params });
    } catch (error) {
        console.error(error);
        return { success: false, error: "An error occured. Try again." };
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
    }

    return responseData;
}

export default {
    createPost,
    getPostsInfo,
    interactWithPost,
    getRecentPosts,
    deletePost,
    createComment,
    getCommentInfo,
    deleteComment,
    savePost,
    removeSavedPost,
}
import "./Navbar.css";
import React, { FC, useEffect, useState, useRef, useReducer } from "react";
import { useHistory, useLocation } from 'react-router-dom';

import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

import Dropdown from 'react-bootstrap/Dropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

import ConnectionButton from "./Connection/ConnectionButton"

import logo from "../Images/Icons/brokerageEmblem.png"
import { ReactComponent as MenuToggleIcon } from "../Images/Icons/MenuToggle.svg"
import { ReactComponent as ChevronDown } from "../Images/Icons/ChevronDown.svg";


import { MAIN_WEBSITE_URL } from "../utils/constants"

const navigationLimitWidth = 1175;

const Navbar: FC<any> = (props) => {
    const { className, transparent, isTelegramWebApp, tokenType } = props

    const history = useHistory();
    const location = useLocation()

    const [isAlphaDropdownOpen, setIsAlphaDropdownOpen] = useState(false)
    const [isAnalysisDropdownOpen, setIsAnalysisDropdownOpen] = useState(false)
    const [showOverlay, setShowOverlay] = useState(false)
    const [currentPage, setCurrentPage] = useState<string | null>(null) // BigBoard & eliteMembership

    useEffect(() => {
        const paths = location.pathname.slice(1, location.pathname.length).split("/")
        const basePath: string = paths[0]
        setCurrentPage(basePath)

    }, [location.pathname])

    // useEffect(() => {
    //     gsap.registerPlugin(ScrollTrigger)
    //     animateNavbar()
    // }, [])

    // function animateNavbar() {
    //     const navbarTimeline = gsap.timeline({
    //         scrollTrigger: {
    //             trigger: "#app-content",
    //             start: "top top",
    //             end: "top top+=500px",
    //             scrub: true,
    //             onLeave: () => gsap.set("#big-board-nav>div", { boxShadow: "0px 11px 31px 0px rgba(0,0,0,0.75)" }),
    //             onEnterBack: () => gsap.set("#big-board-nav>div", { boxShadow: "none" }),
    //         }
    //     });

    //     navbarTimeline.to("#big-board-nav", { background: "rgba(0,0,0,1)", paddingTop: "5px", paddingBottom: "5px" })
    // }

    return (
        <div id="big-board-nav" className={`d-flex justify-content-between align-items-center pt-0 ${className} ${transparent ? "transparent" : ""}`}>
            <div className="d-flex justify-content-between w-100">
                <div className="d-flex align-items-center text-header ps-3 py-1">
                    <img src={logo} id="nav-logo" className="py-1" />
                    <div className="d-flex text-white mx-2 ms-4">
                        <h6 className="mb-0">Alpha Club</h6>
                    </div>
                    <div className="d-flex text-white mx-2">
                        <h6 className="mb-0">Crypto Reports</h6>
                    </div>
                </div>
                <div className="d-flex justify-content-end align-items-center pe-3">
                    <ConnectionButton />
                </div>
            </div>
        </div>
    )
}

export default Navbar;

import "./MainHandler.css"
import React, { FC, useEffect, useState, useRef, useReducer } from "react";
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
// import queryString from "query-string";

import { ToastContainer } from 'react-toastify';

import BigBoard from "./Pages/AlphaClub/BigBoard"
import Navbar from "./Components/Navbar"
import TelegramConnectionLoader from "./Components/TelegramConnectionLoader"
import { AssetClass } from "./utils/types"
import axios from "axios";

const MainHandler: FC<any> = (props) => {

    const history = useHistory();
    const location = useLocation()
    const [locationPaths, setLocationPaths] = useState<string[]>([])

    const [telegramDataToValidate, setTelegramDataToValidate] = useState<string>();
    const [isTelegramWebApp, setIsTelegramWebApp] = useState<boolean>(false);

    useEffect(() => {
        const telegramWebApp = (window as any).Telegram?.WebApp;
        setIsTelegramWebApp(telegramWebApp && telegramWebApp.platform !== "unknown")
    }, [])

    useEffect(() => {
        console.log(location.pathname)

        // location.pathname.replace(/https:\/\/.+\//, "")
        const paths = location.pathname.slice(1, location.pathname.length).split("/")
        console.log(paths)
        setLocationPaths(paths)

        if (paths[0] === "telegram") handleTelegramOrigin();
    }, [location])

    function handleTelegramOrigin() {
        const telegramWebApp = (window as any).Telegram?.WebApp;
        if (telegramWebApp && telegramWebApp.initData.length > 0) {
            setTelegramDataToValidate(telegramWebApp.initData);
        } else {
            history.push(`/${locationPaths.filter((locationPath: string) => locationPath !== "telegram").join("/")}`);
        }

    }

    return (
        <div id="main-handler" className="d-flex flex-column">
            <Navbar isTelegramWebApp={isTelegramWebApp} />
            <div id="app-content" className="d-flex flex-column">
                <Switch>
                    <Redirect exact from='/' to='/big-board/crypto' />
                    <Redirect exact from='/big-board' to='/big-board/crypto' />
                    {locationPaths[0] === "big-board" && <Route path="/big-board" children={<BigBoard isTelegramWebApp={isTelegramWebApp} searchedAssetClass={locationPaths.length > 1 ? locationPaths[1] === "crypto" ? AssetClass.Crypto : AssetClass.NFT : undefined} searchedBoardPostId={locationPaths[2] !== "create" ? locationPaths[2] : undefined} createNewPostIntent={locationPaths[2] === "create"} />}></Route>}
                    <Route path="/telegram" children={<TelegramConnectionLoader telegramDataToValidate={telegramDataToValidate} locationPaths={locationPaths} />}></Route>
                </Switch>
            </div>

            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default MainHandler
import "./BigBoard.css";
import React, { FC, useEffect, useState, useRef, useReducer, useMemo } from "react";
import queryString from "query-string";

import { useHistory } from "react-router-dom";

import mixpanel from "mixpanel-browser";

import { IAlphaPostFilters, PostsQueryField, PostsQueryOrder } from "./types";
import { BoardPostInfo, PostInteraction, AssetClass, TokenInfo } from "../../utils/types"

import NewsFeedWidget from "./Widgets/NewsFeedWidget"
import AlphaPostsWidget from "./Widgets/AlphaPostsWidget"
import LeaderboardWidget from "./Widgets/LeaderboardWidget"
import FiltersWidget from "./Widgets/FiltersWidget"

import AlphaPostViewerModal from "./Components/AlphaPostViewerModal"
import AlphaPostWriterModal from "./Components/AlphaPostWriterModal"

import { animateLoadingSquares, arrangePostComments, delay, getAssetId, searchForTokens } from "../../utils/utils";
import { useUser } from "../../Components/Connection/useUser";
import api from "../../utils/api/api";


export interface SelectedPostFetch {
    fetching: boolean;
    fetchError: boolean;
    deleting: boolean;
    boardPost: BoardPostInfo | null;
}

export interface PostsListFetch {
    fetching: boolean;
    boardPosts: BoardPostInfo[];
    fetchError: boolean;
    allFetched: boolean;
    fetch: Function;
}

const NUM_POSTS_RESULTS_ON_FETCH = 10;
const DEFAULT_FILTERS: IAlphaPostFilters = {
    assets: [], authors: null, assetClass: AssetClass.Crypto, search: null, orderBy: { field: PostsQueryField.CreationTime, direction: PostsQueryOrder.DESCENDING }, loadingFilters: false
}

const BigBoard: FC<any> = (props) => {
    const { isTelegramWebApp, searchedAssetClass, searchedBoardPostId, createNewPostIntent } = props;

    const history = useHistory();

    const { currentUser, setCurrentUser, login } = useUser();

    const [alphaPostFilters, setAlphaPostFilters] = useState<IAlphaPostFilters>({ ...DEFAULT_FILTERS, assetClass: searchedAssetClass ?? DEFAULT_FILTERS.assetClass })
    const [postsListFetch, setPostsListFetch] = useState<PostsListFetch>({ fetching: false, boardPosts: [], fetchError: false, allFetched: false, fetch: fetchBoardPosts });
    const [selectedPostFetch, setSelectedPostFetch] = useState<SelectedPostFetch>({ fetching: false, fetchError: false, deleting: false, boardPost: null });

    const [isWritingPost, setIsWritingPost] = useState<boolean>(createNewPostIntent);

    useEffect(() => {
        checkForUrlFilters();
    }, [])
    async function checkForUrlFilters() {
        const { filterToken, filterAuthor } = queryString.parse(window.location.search);
        if (filterToken) {
            setAlphaPostFilters({ ...alphaPostFilters, loadingFilters: true });
            const filterTokenSearched = await searchForTokens(typeof filterToken === "string" ? filterToken : filterToken[0], 1);
            console.log(filterTokenSearched)
            setAlphaPostFilters({ ...DEFAULT_FILTERS, authors: filterAuthor ? typeof filterAuthor === "string" ? [filterAuthor] : [...filterAuthor] : null, assets: filterTokenSearched, loadingFilters: false });
        }
        else if (filterAuthor) setAlphaPostFilters({ ...DEFAULT_FILTERS, authors: filterAuthor ? typeof filterAuthor === "string" ? [filterAuthor] : [...filterAuthor] : null });
    }

    const didMount = useRef(false);
    useEffect(() => {
        // didMount.current = true;
        setTimeout(() => didMount.current = true, 1);
    }, []);

    useEffect(() => {
        const { filterToken, filterAuthor } = queryString.parse(window.location.search);
        if (!filterToken && !filterAuthor && postsListFetch.boardPosts.length === 0 && searchedBoardPostId === undefined) fetchBoardPosts(undefined, true);
    }, [searchedBoardPostId])
    useEffect(() => {
        console.log("0000000000000000000000000")
        if (didMount.current) console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&")
        const controller = new AbortController();
        let fetchTimeout: NodeJS.Timeout;
        if (didMount.current) fetchTimeout = setTimeout(() => fetchBoardPosts(controller.signal, true), 500);
        return () => {
            controller.abort();
            clearTimeout(fetchTimeout);
        };
    }, [alphaPostFilters]);
    // useEffect(() => {
    //     history.push("/big-board/" + alphaPostFilters.assetClass + `${searchedBoardPostId ? "/" + searchedBoardPostId : ""}`)
    //     setAlphaPostFilters({ ...alphaPostFilters, assets: null })
    // }, [alphaPostFilters.assetClass])

    useEffect(() => {
        if (isWritingPost) mixpanel.track("Open Write Post Modal")
        if (isWritingPost && window.location.pathname.indexOf("/create") === -1) history.push(window.location.pathname + "/create")
        if (!isWritingPost && window.location.pathname.indexOf("/create") !== -1) history.push(window.location.pathname.replace("/create", ""))
    }, [isWritingPost])

    async function fetchBoardPosts(signal?: AbortSignal, clearPreviousFetches = false) {
        if (alphaPostFilters === undefined || alphaPostFilters.loadingFilters || (!clearPreviousFetches && postsListFetch.allFetched)) return;
        setPostsListFetch({ ...postsListFetch, fetching: true, boardPosts: clearPreviousFetches ? [] : postsListFetch.boardPosts, fetchError: false });
        const getRecentPostsPromise = api.boardPost.getRecentPosts(alphaPostFilters.orderBy.direction, alphaPostFilters.orderBy.field, NUM_POSTS_RESULTS_ON_FETCH, clearPreviousFetches ? Date.now() : postsListFetch.boardPosts.length > 0 ? postsListFetch.boardPosts[postsListFetch.boardPosts.length - 1].creation.timestamp : Date.now(), 0, alphaPostFilters.assets?.map((asset: any) => getAssetId(asset)) ?? undefined, alphaPostFilters.assetClass, alphaPostFilters.authors ?? undefined);
        animateLoadingSquares("alpha-posts-widget", getRecentPostsPromise);
        let { success, boardPosts } = await getRecentPostsPromise;
        if (signal?.aborted) return;
        const newBoardPosts = boardPosts.map((post: BoardPostInfo) => arrangePostComments(post))
        setPostsListFetch({ ...postsListFetch, fetching: false, boardPosts: clearPreviousFetches ? newBoardPosts : [...postsListFetch.boardPosts, ...newBoardPosts], fetchError: !success, allFetched: success && newBoardPosts.length < NUM_POSTS_RESULTS_ON_FETCH });
    }

    const handleScroll = () => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight

        if (bottom) {
            if (!isWritingPost && !selectedPostFetch.fetching && !selectedPostFetch.deleting && selectedPostFetch.boardPost === null) fetchBoardPosts();
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {
            passive: true
        });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [selectedPostFetch, isWritingPost, postsListFetch, alphaPostFilters]);

    async function interactWithPost(post: BoardPostInfo, interactionType: PostInteraction) {
        if (currentUser === null) {
            login()
            return
        };
        const isRemoveInteraction = interactionType === PostInteraction.Upvote && post.interactions.upvote.accounts.includes(currentUser.userId) || interactionType === PostInteraction.Downvote && post.interactions.downvote.accounts.includes(currentUser.userId)
        mixpanel.track(`Post Interaction: ${isRemoveInteraction ? "Cancel" : ""} ${interactionType === PostInteraction.Upvote ? "Upvote" : "Downvote"}`, {
            postCreationTimestamp: post.creation.timestamp,
            postCreatorUserId: post.creation.user.userId,
            eliteMemberCreator: post.creation.user.isEliteCreator,
            postTokenSymbol: post.content.asset.info.symbol,
            postType: post.content.postType,
            postPosition: post.content.position,
            postHasTaChart: post.content.postType === "technicalAnalysis" && post.content.prediction.technicalAnalysis!.advancedOptions.taChartUrl !== null
        })

        const initialPostInteractions = JSON.parse(JSON.stringify(post.interactions));
        const interactionResult = api.boardPost.interactWithPost(interactionType.valueOf(), post.id, isRemoveInteraction);
        addInteractionWithPost(post, interactionType)

        if (!(await interactionResult)) {
            const revertedPost = post;
            revertedPost.interactions = initialPostInteractions;
            console.log("Failed interaction, reverting")
            console.log(revertedPost.interactions)
            updatePostInPostsList(revertedPost)
        }

    }
    function addInteractionWithPost(post: BoardPostInfo, interactionType: PostInteraction) {
        if (currentUser === null) return;
        const isCancelation = interactionType === PostInteraction.Upvote ? post.interactions.upvote.accounts.includes(currentUser.userId) : post.interactions.downvote.accounts.includes(currentUser.userId)
        post.interactions.upvote.accounts = post.interactions.upvote.accounts.filter((accountId: string) => accountId !== currentUser.userId);
        post.interactions.downvote.accounts = post.interactions.downvote.accounts.filter((accountId: string) => accountId !== currentUser.userId);

        if (isCancelation) {

        } else if (interactionType === PostInteraction.Upvote) {
            post.interactions.upvote.accounts.push(currentUser.userId);
        } else if (interactionType === PostInteraction.Downvote) {
            post.interactions.downvote.accounts.push(currentUser.userId);
        }

        updatePostInPostsList(post)
    }
    function updatePostInPostsList(post: BoardPostInfo) {
        const postIndex = postsListFetch.boardPosts.map((boardPost: BoardPostInfo) => boardPost.id).indexOf(post.id);
        const newBoardPosts = [...postsListFetch.boardPosts].filter((boardPost: BoardPostInfo) => boardPost.id !== post.id);
        newBoardPosts.splice(postIndex === -1 ? 0 : postIndex, 0, post);
        setPostsListFetch({ ...postsListFetch, boardPosts: newBoardPosts });
    }
    function removePostFromPostsList(postId: string) {
        const newBoardPosts = postsListFetch.boardPosts.filter((boardPost: BoardPostInfo) => boardPost.id !== postId);
        setPostsListFetch({ ...postsListFetch, boardPosts: newBoardPosts });
    }

    async function toggleSavePost(post: BoardPostInfo) {
        if (currentUser === null) {
            login()
            return
        };

        const postAlphaAssetClass = post.content.asset.assetClass;

        const isSaveOperation = !currentUser.userAlphaInfo[postAlphaAssetClass].savedPostsIds.includes(post.id);
        const operationResultPromise = isSaveOperation ? api.boardPost.savePost(post.id, post.content.asset.assetClass === AssetClass.Crypto) : api.boardPost.removeSavedPost(post.id, post.content.asset.assetClass === AssetClass.Crypto);
        mixpanel.track(`${isSaveOperation ? "Add" : "Remove"} Saved Post`, {
            postCreationTimestamp: post.creation.timestamp,
            postCreatorUserId: post.creation.user.userId,
            eliteMemberCreator: post.creation.user.isEliteCreator,
            postTokenSymbol: post.content.asset.info.symbol,
            postType: post.content.postType,
            postPosition: post.content.position,
            postHasTaChart: post.content.postType === "technicalAnalysis" && post.content.prediction.technicalAnalysis!.advancedOptions.taChartUrl !== null
        })

        function runLocalOperation(isSaveOperation: boolean) {
            isSaveOperation ? setCurrentUser({ ...currentUser, userAlphaInfo: { ...currentUser!.userAlphaInfo, [postAlphaAssetClass]: { ...currentUser!.userAlphaInfo[postAlphaAssetClass], savedPostsIds: [...currentUser!.userAlphaInfo[postAlphaAssetClass].savedPostsIds, post.id] } } })
                : setCurrentUser({ ...currentUser, userAlphaInfo: { ...currentUser!.userAlphaInfo, [postAlphaAssetClass]: { ...currentUser!.userAlphaInfo[postAlphaAssetClass], savedPostsIds: [...currentUser!.userAlphaInfo[postAlphaAssetClass].savedPostsIds.filter((postId) => postId !== post.id)] } } })
        }
        runLocalOperation(isSaveOperation)
        if (!(await operationResultPromise).success) {
            runLocalOperation(!isSaveOperation)
        }
    }

    function verifyIfPostMeetsFilters(boardPost: BoardPostInfo): boolean {
        if (alphaPostFilters === undefined) return true;
        if (alphaPostFilters.assets !== null && !alphaPostFilters.assets.map(asset => asset.symbol).includes(boardPost.content.asset.info.symbol)) return false;
        if (alphaPostFilters.authors !== null && !alphaPostFilters.authors.includes(boardPost.creation.user.userId)) return false;
        if (alphaPostFilters.assetClass !== null && alphaPostFilters.assetClass !== boardPost.content.asset.assetClass) return false;
        return true;
    }

    return (
        <div id="big-board" className="d-flex">
            {/* <div id="big-board-container" className="d-flex"> */}
            <div id="big-board-news-feed-container" className="d-flex flex-column">
                <NewsFeedWidget />
            </div>
            <div id="big-board-alpha-posts-container" className="d-flex flex-column">
                <AlphaPostsWidget postsListFetch={postsListFetch} fetchBoardPosts={fetchBoardPosts} interactWithPost={interactWithPost} onPostClick={(boardPost: BoardPostInfo) => setSelectedPostFetch({ ...selectedPostFetch, fetching: false, fetchError: false, boardPost })} toggleSavePost={toggleSavePost} />
            </div>
            <div id="big-board-filters-and-leaderboard-container" className="d-flex flex-column justify-content-between">
                <FiltersWidget alphaPostFilters={alphaPostFilters} setAlphaPostFilters={setAlphaPostFilters} setIsWritingPost={setIsWritingPost} />
                <LeaderboardWidget />
            </div>

            <AlphaPostViewerModal assetClass={alphaPostFilters.assetClass} searchedBoardPostId={searchedBoardPostId} selectedPostFetch={selectedPostFetch} setSelectedPostFetch={setSelectedPostFetch} interactWithPost={interactWithPost} removePostFromPostsList={removePostFromPostsList} toggleSavePost={toggleSavePost} />

            <AlphaPostWriterModal isWritingPost={isWritingPost} setIsWritingPost={setIsWritingPost} assetClass={alphaPostFilters.assetClass} verifyIfPostMeetsFilters={verifyIfPostMeetsFilters} updatePostInPostsList={updatePostInPostsList} selectedPostFetch={selectedPostFetch} setSelectedPostFetch={setSelectedPostFetch} />
            {/* </div> */}
        </div >
    )
};

export default BigBoard;
import { type ReactNode, useEffect, useState, createContext, useContext } from "react";

import mixpanel from "mixpanel-browser";

import { app } from "../../utils/firebaseApp"
import { getAuth, signInWithPopup, TwitterAuthProvider } from "firebase/auth";

import api from "../../utils/api/api";

import { UserAccount } from "../../utils/types";
import { UserContext } from "./useUser"

const auth = getAuth(app);
const provider = new TwitterAuthProvider();

export function UserProvider(
    { children }: any
) {
    const [loggingIn, setLoggingIn] = useState(false)
    const [currentUser, setCurrentUser] = useState<UserAccount | null>(null)

    useEffect(() => {
        tryReloginWithToken()
    }, [])

    useEffect(() => {
        console.log("Current user: " + currentUser)
    }, [currentUser])

    const login = async (platform: "twitter" | "telegram" = "twitter", data: any = undefined, saveToken = true) => {
        if (loggingIn) return false;

        setLoggingIn(true)
        setCurrentUser(null)

        if (platform === "twitter") return await loginWithTwitter(saveToken);
        else if (platform === "telegram") return await loginWithTelegram(data, saveToken);
    }

    const loginWithTwitter = async (saveToken: boolean) => {
        signInWithPopup(auth, provider)
            .then(async (result) => {
                // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
                // You can use these server side with your app's credentials to access the Twitter API.
                const credential = TwitterAuthProvider.credentialFromResult(result);
                // if (credential === null) return;
                const token = credential?.accessToken;
                const secret = credential?.secret;
                if (token === undefined || secret === undefined) {
                    setLoggingIn(false)
                    return false;
                }

                const loginResponse = await api.auth.twitter.connectWithTwitter(token, secret)
                setLoggingIn(false)

                if (loginResponse === null || !loginResponse.success) {
                    return false;
                }
                console.log(loginResponse.userAccount)
                setCurrentUser(loginResponse.userAccount);
                if (saveToken) localStorage.setItem('token', loginResponse.token)
                mixpanel.track("Login", {
                    platform: "Twitter",
                    withToken: false
                })
                return true;
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(errorCode, errorMessage);

                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = TwitterAuthProvider.credentialFromError(error);

                setLoggingIn(false)
                return false;
            });
    }

    const loginWithTelegram = async (telegramInitData: string, saveToken: boolean) => {
        if (telegramInitData === undefined) return false;

        const loginResponse = await api.auth.telegram.connectWithTelegram(telegramInitData);
        setLoggingIn(false)

        if (loginResponse === null || !loginResponse.success) {
            return false;
        }
        console.log(loginResponse.userAccount)
        setCurrentUser(loginResponse.userAccount);
        if (saveToken) localStorage.setItem('token', loginResponse.token)
        mixpanel.track("Login", {
            platform: "Telegram",
            withToken: false
        })
        return true;
    }

    const tryReloginWithToken = async () => {
        const userToken = localStorage.getItem("token");
        if (userToken !== null) {
            setLoggingIn(true)
            console.log("Relogging with token")
            const userAccount = await api.auth.fetchConnectedUserAccount(userToken);
            console.log(userAccount)
            setCurrentUser(userAccount)
            setLoggingIn(false)
            mixpanel.track("Login", {
                withToken: true
            })
            return true;
        }
    }

    const logout = () => {
        setCurrentUser(null)
        localStorage.removeItem('token')
        mixpanel.track("Logout")
    }

    // const signLoginMessage = async (
    //     address: `0x${string}`,
    //     message: string
    // ) => {
    //     const ethWindow: any = window.ethereum;
    //     const provider = new ethers.providers.Web3Provider(ethWindow)
    //     const signer = provider.getSigner();
    //     const signature = await signer.signMessage(message);
    //     return await api.auth.loginBrokerAccount(address, signature);
    // }

    return (
        <UserContext.Provider
            value={{
                loggingIn,
                currentUser,
                setCurrentUser,
                login,
                logout
            }}
        >
            {children}
        </UserContext.Provider>
    );
}
import axios from "axios";

import { API_URL } from "../api"

async function getDiscordInfoFromCode(code: string): Promise<any> {
    console.log(axios.defaults.headers.common)
    let response;
    try {
        response = await axios.get(`${API_URL}/auth/discord/request_access_token?code=${code}`, { withCredentials: true });
    } catch (err) {
        console.error(err);
        return null;
    }
    const responseData = response.data;

    if (!responseData.success) {
        console.error(response);
        return null
    }

    return responseData.userInfo;

}

export default {
    getDiscordInfoFromCode
}
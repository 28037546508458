import axios from "axios";
import discord from "./discord"
import twitter from "./twitter"
import telegram from "./telegram"

import { API_URL } from "../api"
import { UserAccount } from "../../types"

// async function getUserMessageToSign(
//     walletAddress: string
// ): Promise<{ message: string, nonce: string } | null> {
//     const data = JSON.stringify({ walletAddress });
//     const customConfig = {
//         headers: {
//             'Content-Type': 'application/json'
//         }
//     };

//     let response;
//     try {
//         response = await axios.post(`${API_URL}/users/login/getSignatureMessage`, data, customConfig);
//     } catch (error) {
//         console.error(error);
//         return null
//     }

//     const responseData = response.data;
//     if (!responseData.success) {
//         console.error(responseData.error);
//         return null;
//     }

//     return responseData.signatureMessageInfo;
// }

// async function loginBrokerAccountWithWallet(
//     walletPubkey: string,
//     signature: string
// ): Promise<{ userBrokerAccount: UserAccount, success: boolean, token: string } | null> {
//     const data = JSON.stringify({ walletPubkey, signature });
//     const customConfig = {
//         headers: {
//             'Content-Type': 'application/json'
//         }
//     };

//     let response;
//     try {
//         response = await axios.post(`${API_URL}/users/login/verifySignature`, data, customConfig);
//     } catch (error) {
//         console.error(error);
//         return null
//     }

//     const responseData = response.data;
//     if (!responseData.success) {
//         console.error(responseData.error);
//         return null;
//     }

//     axios.defaults.headers.common['authorization'] = "Bearer " + responseData.token;

//     return responseData;
// }

async function fetchConnectedUserAccount(
    optionalToken: string | null = null
): Promise<UserAccount | null> {
    if (optionalToken !== null) axios.defaults.headers.common['authorization'] = "Bearer " + optionalToken;

    let response;
    try {
        response = await axios.get(`${API_URL}/users/user-info/connected`);
    } catch (error) {
        console.error(error);
        return null;
    }
    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        return null
    }

    // await delay(30000)
    return responseData.userAccount;
}
async function fetchUserAccount(
    optionalToken: string | null = null
): Promise<UserAccount | null> {
    if (optionalToken !== null) axios.defaults.headers.common['authorization'] = "Bearer " + optionalToken;

    let response;
    try {
        response = await axios.get(`${API_URL}/users/user-info`);
    } catch (error) {
        console.error(error);
        return null;
    }
    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
        return null
    }

    // await delay(30000)
    return responseData.userAccount;
}

export default {
    // getUserMessageToSign,
    // loginBrokerAccountWithWallet,
    fetchConnectedUserAccount,
    fetchUserAccount,
    discord,
    twitter,
    telegram,
}

export const discordAuthUrl = "https://us-central1-wolves-capital.cloudfunctions.net/api/v1/auth/discord/" //"http://localhost:3001/v1/auth/discord/" // "https://discord.com/api/oauth2/authorize?client_id=1037758833948368960&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fbrokers-grove%2Faccount&response_type=code&scope=identify%20guilds"
export const twitterAuthUrl = "https://us-central1-wolves-capital.cloudfunctions.net/api/v1/auth/twitter/" //"http://localhost:3001/v1/auth/twitter/"
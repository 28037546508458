import axios from "axios";

import { API_URL } from "../api"

async function getMarketNews(
    queryLength?: number,
    createdBefore?: number,
) {
    const params: any = {}
    if (queryLength) params.queryLength = queryLength;
    if (createdBefore) params.createdBefore = createdBefore;
    let response;
    try {
        response = await axios.get(`${API_URL}/alpha-club/market-news/timeline`, { params });
    } catch (error) {
        console.error(error);
        return { success: false, error };
    }

    const responseData = response.data;
    if (!responseData.success) {
        console.error(responseData.error);
    }

    return responseData;
}

export default {
    getMarketNews
}